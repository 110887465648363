import React from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { Text } from '@bufferapp/components'
import Label from '@bufferapp/ui/Text'
import Link from '@bufferapp/ui/Link'
import Button from '@bufferapp/ui/Button'
import { OpenNew, ChartLine, Carousel, Movie } from '@bufferapp/ui/Icon'
import { ProfileLegend, GridItem } from '~/shared-components'
import { metricsConfig } from './metricsConfig'

dayjs.extend(relativeTime)

const Container = styled.div`
  width: 1000px;
  height: 500px;
  background: #ffffff;
  border-radius: 3px;
  display: flex;
  overflow: hidden;
`

const Divider = styled.div``

const TextContentDivider = styled.div`
  padding: 24px;
  position: relative;
  width: 100%;
`

const LabelContainer = styled.div`
  label {
    display: flex;
    align-content: center;
    position: absolute;
    bottom: 40px;

    svg {
      padding-right: 8px;
    }
  }
`

const HeadingDivider = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`

const LinkPosition = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  bottom: 70px;
`

const Image = styled.img`
  height: 100%;
  width: auto;
`

const ImagePlaceholder = styled.div`
  height: 100%;
  width: 500px;
  background: #eee;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
`

const Grid = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  margin-bottom: 32px;
  text-transform: capitalize;
  padding-left: 0;

  li {
    width: 100%;
    padding-right: 0;
  }
`

const Separator = styled.span`
  height: 1px;
  border-top: 1px dashed #afafaf;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;
  display: block;
`

const TextWindow = styled.div`
  max-height: 4.5rem;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  position: relative;

  ::after {
    content: '';
    background: linear-gradient(
      270deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0) 103.3%
    );
    display: block;
    width: 250px;
    height: 20px;
    position: absolute;
    right: 0;
    bottom: 0;
  }
`

const AltImageHolder = styled.div`
  height: 100%;
  width: 100%;
  background: #fbfcff;
  box-shadow: inset 0px 0px 16px rgba(63, 142, 227, 0.12);
  border-radius: 2px 0px 0px 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 62px;
  padding-right: 62px;
`

const AltImageText = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  color: #3f8ee3;
  overflow: hidden;
  width: 376px;
   {
    /* got this from https://css-tricks.com/line-clampin/ */
  }
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
`

const IconWrapper = styled.div`
  position: absolute;
  z-index: 5;
  top: 25px;
  right: 25px;
  background: rgba(255, 255, 255, 0.75);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 50%;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const DescriptionText = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #5b6269;
`

// @ts-expect-error TS(7006) FIXME: Parameter 'stats' implicitly has an 'any' type.
function mapMetrics(stats, boostedStats, service) {
  const keys = Object.keys(stats)
  const cleanedMetrics = []

  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  metricsConfig[service].map((metricConfig, index) => {
    if (!keys.includes(metricConfig.key)) return
    const metricConfigInfo = {
      label: metricConfig.label,
      value: stats[metricConfig.key],
      paid: boostedStats ? boostedStats[metricConfig.key] : null,
    }
    cleanedMetrics.push(metricConfigInfo)
  })

  if (boostedStats && 'spend' in boostedStats) {
    const [currency, value] = boostedStats.spend.split('_')
    cleanedMetrics.push({
      label: 'Total spend',
      value,
      currency,
    })
  }

  return cleanedMetrics
}

// @ts-expect-error TS(7006) FIXME: Parameter 'timestamp' implicitly has an 'any' type... Remove this comment to see the full error message
function normalizeTimestamp(timestamp) {
  return timestamp.toString().match(/0{3}$/)
    ? dayjs(timestamp).fromNow()
    : dayjs(timestamp * 1000).fromNow()
}

// @ts-expect-error TS(7031) FIXME: Binding element 'post' implicitly has an 'any' typ... Remove this comment to see the full error message
export const Card = ({ post }) => {
  const cleanedMetrics = mapMetrics(
    post.statistics,
    post.boosted_statistics,
    post.profile.service,
  )

  return (
    <Container>
      <Divider>
        <ImagePlaceholder>
          {post.type === 'carousel' && (
            <IconWrapper>
              <Carousel />
            </IconWrapper>
          )}
          {post.type === 'video' && (
            <IconWrapper>
              <Movie />
            </IconWrapper>
          )}
          {post.media.thumbnail ? (
            <Image src={post.media.thumbnail}></Image>
          ) : (
            <AltImageHolder>
              <AltImageText>{post.text}</AltImageText>
            </AltImageHolder>
          )}
        </ImagePlaceholder>
        <LinkPosition>
          <Link href={post.serviceLink} newTab>
            {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; icon: Element... Remove this comment to see the full error message */}
            <Button
              type="secondary"
              icon={<OpenNew />}
              onClick={() => {}}
              label="View post on network"
            />
          </Link>
        </LinkPosition>
      </Divider>
      <TextContentDivider>
        <HeadingDivider>
          <Text color="outerSpace" weight="semi-bold">
            {dayjs(post.date).format('MMMM D, YYYY h:mma')}
          </Text>
          <ProfileLegend profile={post.profile} />
        </HeadingDivider>
        <TextWindow>
          <DescriptionText>{post.text}</DescriptionText>
        </TextWindow>
        <Separator></Separator>
        <Grid>
          {cleanedMetrics.map((stat, index) => {
            return (
              <GridItem
                key={index}
                metric={stat}
                showPercentSign={stat.label == 'Engagement Rate'}
              />
            )
          })}
        </Grid>
        {post.last_checked && (
          <LabelContainer>
            <Label htmlFor="lastUpdated" type="label" color="gray">
              <ChartLine /> Last updated {normalizeTimestamp(post.last_checked)}
            </Label>
          </LabelContainer>
        )}
      </TextContentDivider>
    </Container>
  )
}
