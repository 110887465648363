import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'
import { Button } from '~/shared-components'
import { CloseIcon, outerSpaceLight } from '@bufferapp/components'

const DeleteButton = styled(Button)`
  &:before {
    width: 1px
    height: 1px;
    display: block;
    content: "";
  }

  &:hover {
    cursor: pointer;
    border: 1px solid #ff1e1e;
    box-shadow: 0 1px 2px ${outerSpaceLight};
  }
  &:active {
    background-color: #fff8f8;
    box-shadow: 0 1px 4px ${outerSpaceLight};
  }
`

// @ts-expect-error TS(7031) FIXME: Binding element '_id' implicitly has an 'any' type... Remove this comment to see the full error message
const RemoveButton = ({ _id, removeReport }) => (
  <DeleteButton
    noStyle
    // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
    onClick={(event) => {
      event.stopPropagation()
      removeReport(_id)
    }}
  >
    <CloseIcon color="#ff1e1e" size="small" />
  </DeleteButton>
)

RemoveButton.propTypes = {
  _id: PropTypes.string.isRequired,
  removeReport: PropTypes.func,
}

RemoveButton.defaultProps = {
  removeReport: null,
}

export default RemoveButton
