import { actionTypes as profileActionTypes } from '~/profile-selector'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import { actions } from '@bufferapp/async-data-fetch'
import { actionTypes as dateActionTypes } from '~/date-picker'
import {
  actionTypes as exportActionTypes,
  actions as exportActions,
} from '~/png-export'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)

// @ts-expect-error TS(7006) FIXME: Parameter 'store' implicitly has an 'any' type.
export default (store) => (next) => (action) => {
  // eslint-disable-line no-unused-vars
  const { dispatch, getState } = store
  switch (action.type) {
    case dateActionTypes.SET_DATE_RANGE:
      if (getState().profiles.selectedProfileId) {
        if (getState().profiles.selectedProfileService !== 'linkedin') {
          dispatch(
            actions.fetch({
              name: 'answers',
              args: {
                profileId: getState().profiles.selectedProfileId,
                startDate: action.startDate,
                endDate: action.endDate,
                timezone: getState().profiles.selectedProfile.timezone,
              },
            }),
          )
        }
      }
      break
    case profileActionTypes.SELECT_PROFILE:
      if (action.profile.service !== 'linkedin') {
        dispatch(
          actions.fetch({
            name: 'answers',
            args: {
              profileId: action.profile.id,
              startDate: getState().date.startDate,
              endDate: getState().date.endDate,
              timezone: action.profile.timezone
                ? action.profile.timezone
                : dayjs.tz.guess(),
            },
          }),
        )
      }
      break
    case profileActionTypes.REFRESH_PROFILES:
      if (getState().profiles.selectedProfileId) {
        if (getState().profiles.selectedProfileService !== 'linkedin') {
          dispatch(
            actions.fetch({
              name: 'answers',
              args: {
                profileId: getState().profiles.selectedProfileId,
                profileService: getState().profiles.selectedProfileService,
                startDate: getState().date.startDate,
                endDate: getState().date.endDate,
                timezone: getState().profiles.selectedProfile.timezone,
              },
            }),
          )
        }
      }
      break
    case exportActionTypes.EXPORT_TO_PNG_START:
      dispatch(
        exportActions.exportChart(
          'js-dom-to-png-answers-summary',
          'answers-summary',
        ),
      )
      dispatch(
        exportActions.exportChart(
          'js-dom-to-png-answers-chart-day',
          'answers-day',
        ),
      )
      dispatch(
        exportActions.exportChart(
          'js-dom-to-png-answers-chart-type',
          'answers-type',
        ),
      )
      dispatch(
        exportActions.exportChart(
          'js-dom-to-png-answers-chart-frequency',
          'answers-frequency',
        ),
      )
      break
    default:
      break
  }
  return next(action)
}
