import React, { useState } from 'react'
import styled from 'styled-components'
import { Text } from '@bufferapp/ui'
import { orangeLighter, orangeDarker } from '@bufferapp/ui/style/colors'
import CrossIcon from '@bufferapp/ui/Icon/Icons/Cross'
import WarningIcon from '@bufferapp/ui/Icon/Icons/Warning'
import { useSplitEnabled } from '@bufferapp/features'
import {
  setDismissedCookie,
  hasDismissedCookie,
  getUnsupportedChannels,
} from '../utils'
import { ButtonStyled, Banner } from './style'
import type { Campaign } from '~/utils/types'

const BannerStyled = styled(Banner)`
  background-color: ${orangeLighter};
  color: ${orangeDarker};

  button {
    color: ${orangeDarker};
  }
`

interface UnsupportedChannelBannerProps {
  campaign: Campaign
}

const UnsupportedChannelBanner = ({
  campaign,
}: UnsupportedChannelBannerProps): JSX.Element | null => {
  const [dismissed, setDismissed] = useState(false)
  const unsupportedChannelsCount = getUnsupportedChannels(
    campaign.channels,
  ).length
  const singular = unsupportedChannelsCount === 1
  const cookieName = `CampaignUnsupporterChannelBanner_${campaign.id}`

  const { isEnabled: isCampaignsToTagsEnabled } =
    useSplitEnabled('campaigns-to-tags')

  if (unsupportedChannelsCount === 0) return null
  if (dismissed) return null
  if (hasDismissedCookie(cookieName)) return null

  return (
    <BannerStyled>
      <WarningIcon size="medium" />
      <Text type="p">
        {isCampaignsToTagsEnabled
          ? `Looks like there are posts with this tag for ${
              singular ? 'a channel' : 'channels'
            } that ${singular ? 'is' : 'are'} not supported in Analyze.`
          : `Looks like there ${
              singular ? 'is a channel' : 'are multiple channels'
            } in this campaign that ${
              singular ? 'is' : 'are'
            } not supported in Analyze.`}
      </Text>
      <ButtonStyled
        type="text"
        icon={<CrossIcon />}
        hasIconOnly
        onClick={(): void => {
          setDismissed(true)
          setDismissedCookie(cookieName)
        }}
        label="dismiss"
      />
    </BannerStyled>
  )
}

export default UnsupportedChannelBanner
