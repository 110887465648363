import PropTypes from 'prop-types'
import React from 'react'
import reactDOM from 'react-dom/server'
import Text from '@bufferapp/ui/Text'
import { DemographicTooltip, TruncatedNumber } from '~/shared-components'

import map from './world-highres.geo.json'

import countrieNames from './countries'

const COLOR = '107, 129, 255'

// @ts-expect-error TS(7006) FIXME: Parameter 'label' implicitly has an 'any' type.
function formatGroupLabel(label) {
  return label.replace('Your ', '').toLowerCase()
}

// @ts-expect-error TS(7006) FIXME: Parameter 'total' implicitly has an 'any' type.
function calculatePercentage(total, value) {
  return (value / total) * 100
}

// @ts-expect-error TS(7006) FIXME: Parameter 'countries' implicitly has an 'any' type... Remove this comment to see the full error message
function calculateCitiesTotalsCount(countries) {
  // @ts-expect-error TS(7006) FIXME: Parameter 'ac' implicitly has an 'any' type.
  return countries.reduce((ac, val) => ac + val.value, 0)
}

// @ts-expect-error TS(7006) FIXME: Parameter 'series' implicitly has an 'any' type.
function applyColors(series, valuesRange) {
  const minValue = valuesRange.min
  const maxValue = valuesRange.max
  const minOpacity = 0.2
  const maxOpacity = 1
  // @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
  return series.map((data) => {
    const opacity =
      ((data.value - minValue) / (maxValue - minValue)) *
        (maxOpacity - minOpacity) +
      minOpacity
    const color = `rgba(${COLOR},${opacity})`
    return Object.assign(data, {
      color,
    })
  })
}

// @ts-expect-error TS(7006) FIXME: Parameter 'countries' implicitly has an 'any' type... Remove this comment to see the full error message
function formatSeries(countries, groupLabel) {
  const total = calculateCitiesTotalsCount(countries)
  const valuesRange = {
    max: countries[0].value,
    min: countries[countries.length - 1].value,
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'country' implicitly has an 'any' type.
  const data = countries.map((country) => {
    const percentage = calculatePercentage(total, country.value)
    return {
      ...country,
      color: COLOR,
      groupLabel,
      countryCode: country.label,
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      label: countrieNames[country.label],
      percentage,
    }
  })

  return [
    {
      data: applyColors(data, valuesRange),
      joinBy: ['iso-a2', 'countryCode'],
      type: 'map',
      nullColor: `rgba(${COLOR},0.1)`,
      borderColor: '#fff',
    },
  ]
}

// @ts-expect-error TS(7006) FIXME: Parameter 'group' implicitly has an 'any' type.
function extractMetrics(group) {
  // @ts-expect-error TS(7006) FIXME: Parameter 'metric' implicitly has an 'any' type.
  return group.metrics.find((metric) => metric.key === 'country').values
}

// @ts-expect-error TS(7006) FIXME: Parameter 'group' implicitly has an 'any' type.
export default (group) => {
  const countries = extractMetrics(group)
  return {
    chart: {
      map,
    },
    mapNavigation: {
      enabled: false,
    },

    exporting: {
      enabled: false,
    },
    title: null,
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      borderRadius: 7,
      borderWidth: 0,
      formatter() {
        // @ts-expect-error TS(2339) FIXME: Property 'point' does not exist on type '{ backgro... Remove this comment to see the full error message
        return reactDOM.renderToStaticMarkup(<ChartTooltip {...this.point} />)
      },
      followPointer: false,
      shadow: false,
      useHTML: true,
    },
    plotOptions: {
      map: {},
    },
    series: formatSeries(countries, formatGroupLabel(group.label)),
  }
}

// @ts-expect-error TS(7031) FIXME: Binding element 'value' implicitly has an 'any' ty... Remove this comment to see the full error message
const ChartTooltip = ({ value, label, groupLabel, percentage }) => (
  <DemographicTooltip title={label}>
    <Text type="label" color="white">
      <TruncatedNumber>{value}</TruncatedNumber> {groupLabel} (
      <TruncatedNumber showPercentSign>{percentage}</TruncatedNumber>)
    </Text>
  </DemographicTooltip>
)

ChartTooltip.propTypes = {
  groupLabel: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  percentage: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}
