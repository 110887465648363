import React from 'react'
import Modal from '@bufferapp/ui/Modal'
import Carousel from '@bufferapp/ui/Carousel'
import { Card } from './components/Card'

// @ts-expect-error TS(7031) FIXME: Binding element 'posts' implicitly has an 'any' ty... Remove this comment to see the full error message
const ContentModal = ({ posts, closeModal }) => {
  let allPosts = 0
  // @ts-expect-error TS(7006) FIXME: Parameter 'post' implicitly has an 'any' type.
  posts.map((post) => {
    if (post.details.length >= 1) {
      allPosts = allPosts + post.details.length
    }
  })
  return (
    <Modal
      // @ts-expect-error TS(2322) FIXME: Type '{ children: Element... Remove this comment to see the full error message
      width="1100px"
      closeButton={{ callback: () => closeModal() }}
      noBackground
    >
      <div style={allPosts === 1 ? { margin: '0 50px' } : {}}>
        {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
        <Carousel width="1000px">
          {/* @ts-expect-error TS(7006) FIXME: Parameter 'post' implicitly has an 'any' type. */}
          {posts.map((post) =>
            // @ts-expect-error TS(7006) FIXME: Parameter 'singlePost' implicitly has an 'any' typ... Remove this comment to see the full error message
            post.details.map((singlePost) => (
              <Card post={singlePost} key={post.id} />
            )),
          )}
        </Carousel>
      </div>
    </Modal>
  )
}

export default ContentModal
