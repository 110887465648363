import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { ContentCard, Page, Watermark } from '~/shared-components'
import CampaignPosts from '~/campaign-posts-table'
import CampaignPulse from '~/campaign-pulse'
import CampaignPostsMetrics from '~/campaign-posts-metrics'
import ProfileLoader from '~/profile-loader'
import { useAccount, UserIsAdmin } from '~/account'
import CrossSell from '../CrossSell'
import CreateCampaign from '../CreateCampaign'
import Header from '../Header'
import { EmptyCampaign } from '../EmptyCampaign'
import { useSplitEnabled } from '@bufferapp/features'

// @ts-expect-error TS(7031) FIXME: Binding element 'selectedCampaign' implicitly has ... Remove this comment to see the full error message
const Campaign = ({ selectedCampaign, profiles, fetch }) => {
  useEffect(fetch, [selectedCampaign.id])
  return (
    <ContentCard
      subtabs={<Header profiles={profiles} campaign={selectedCampaign} />}
    >
      {selectedCampaign.sent === 0 && (
        <EmptyCampaign selectedCampaign={selectedCampaign} />
      )}
      {selectedCampaign.sent > 0 && (
        <React.Fragment>
          <CampaignPulse />
          <CampaignPostsMetrics />
          <CampaignPosts />
        </React.Fragment>
      )}
      <Watermark />
    </ContentCard>
  )
}

const Campaigns = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'campaigns' implicitly has an 'any... Remove this comment to see the full error message
  campaigns,
  // @ts-expect-error TS(7031) FIXME: Binding element 'loading' implicitly has an 'any' ... Remove this comment to see the full error message
  loading,
  // @ts-expect-error TS(7031) FIXME: Binding element 'location' implicitly has an 'any'... Remove this comment to see the full error message
  location,
  // @ts-expect-error TS(7031) FIXME: Binding element 'renderCrossSell' implicitly has a... Remove this comment to see the full error message
  renderCrossSell,
  // @ts-expect-error TS(7031) FIXME: Binding element 'selectedCampaign' implicitly has ... Remove this comment to see the full error message
  selectedCampaign,
  // @ts-expect-error TS(7031) FIXME: Binding element 'profiles' implicitly has an 'any'... Remove this comment to see the full error message
  profiles,
  // @ts-expect-error TS(7031) FIXME: Binding element 'fetch' implicitly has an 'any' ty... Remove this comment to see the full error message
  fetch,
  // @ts-expect-error TS(7031) FIXME: Binding element 'fetchCampaigns' implicitly has an... Remove this comment to see the full error message
  fetchCampaigns,
  // @ts-expect-error TS(7031) FIXME: Binding element 'setTagsStatus' implicitly has an ... Remove this comment to see the full error message
  setTagsStatus,
}) => {
  const account = useAccount()
  const { isEnabled: isCampaignsToTagsEnabled } =
    useSplitEnabled('campaigns-to-tags')
  useEffect(() => {
    // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type '{}'.
    if (account.currentOrganization.id) {
      // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type '{}'.
      fetchCampaigns(account.currentOrganization.id)
    }
    // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type '{}'.
  }, [account.currentOrganization.id])

  useEffect(() => {
    setTagsStatus(isCampaignsToTagsEnabled)
  }, [isCampaignsToTagsEnabled])

  return (
    <React.Fragment>
      {renderCrossSell && <CrossSell />}
      {!loading && !renderCrossSell && (
        <Page location={location}>
          {campaigns.length === 0 && (
            <UserIsAdmin hasPageContent>
              <CreateCampaign />
            </UserIsAdmin>
          )}
          {campaigns.length > 0 && (
            <UserIsAdmin hasPageContent>
              <ProfileLoader>
                <Campaign
                  profiles={profiles}
                  selectedCampaign={selectedCampaign}
                  fetch={fetch}
                />
              </ProfileLoader>
            </UserIsAdmin>
          )}
        </Page>
      )}
    </React.Fragment>
  )
}

Campaigns.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
  selectedCampaign: PropTypes.shape(),
  renderCrossSell: PropTypes.bool.isRequired,
  // @ts-expect-error TS(2345) FIXME: Argument of type '<P extends ValidationMap<any>>(t... Remove this comment to see the full error message
  campaigns: PropTypes.arrayOf(PropTypes.shape),
  loading: PropTypes.bool,
  profiles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ),
  setTagsStatus: PropTypes.func,
}

Campaigns.defaultProps = {
  campaigns: [],
  loading: false,
  selectedCampaign: null,
  profiles: [],
  setTagsStatus: () => {},
}

export default Campaigns
