// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import { actionTypes as asyncDataFetchActionTypes } from '@bufferapp/async-data-fetch'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import keyWrapper from '@bufferapp/keywrapper'
import { dataParser } from './query'

export const actionTypes = keyWrapper('COMPARE_CHART', {
  SAVE: 'SAVE',
  SELECT_DAILY_MODE: 'SELECT_DAILY_MODE',
  SELECT_METRIC: 'SELECT_METRIC',
  TOGGLE_PREVIOUS_PERIOD: 'TOGGLE_PREVIOUS_PERIOD',
})

const initialState = {
  loading: true,
  hasError: false,
  metrics: { totals: [], daily: [] },
  selectedMetricLabel: '',
  visualizePreviousPeriod: false,
  dailyMode: 0,
}

// @ts-expect-error TS(7006) FIXME: Parameter 'metrics' implicitly has an 'any' type.
function isMetricValid(metrics, metricLabel) {
  // @ts-expect-error TS(7006) FIXME: Parameter 'm' implicitly has an 'any' type.
  const labels = metrics.map((m) => m.label)
  return labels.indexOf(metricLabel) !== -1
}

// @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
function getSelectedMetricLabelOnFetch(state, result) {
  const totals = result.totals
  if (totals.length > 0) {
    if (
      state.selectedMetricLabel !== '' &&
      isMetricValid(totals, state.selectedMetricLabel)
    ) {
      return state.selectedMetricLabel
    }
    return totals[0].label
  }
  return ''
}

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
export default (state = initialState, action) => {
  switch (action.type) {
    case `compare_${asyncDataFetchActionTypes.FETCH_START}`:
      return initialState
    case `compare_${asyncDataFetchActionTypes.FETCH_FAIL}`:
      return {
        ...initialState,
        loading: false,
        hasError: true,
      }
    case actionTypes.SAVE:
    case `compare_${asyncDataFetchActionTypes.FETCH_SUCCESS}`:
      return {
        ...state,
        loading: false,
        metrics: action.result,
        selectedMetricLabel: getSelectedMetricLabelOnFetch(
          state,
          action.result,
        ),
      }
    case `compare_${actionTypes.SELECT_METRIC}`:
      return Object.assign({}, state, {
        selectedMetricLabel: action.metricLabel,
      })
    case `compare_${actionTypes.SELECT_DAILY_MODE}`:
      return Object.assign({}, state, {
        dailyMode: action.mode,
      })
    case `compare_${actionTypes.TOGGLE_PREVIOUS_PERIOD}`:
      return Object.assign({}, state, {
        visualizePreviousPeriod: !state.visualizePreviousPeriod,
      })
    default:
      return state
  }
}

export const actions = {
  togglePreviousPeriod() {
    return {
      type: `compare_${actionTypes.TOGGLE_PREVIOUS_PERIOD}`,
    }
  },
  // @ts-expect-error TS(7006) FIXME: Parameter 'metricLabel' implicitly has an 'any' ty... Remove this comment to see the full error message
  selectMetric(metricLabel) {
    return {
      type: `compare_${actionTypes.SELECT_METRIC}`,
      metricLabel,
    }
  },
  // @ts-expect-error TS(7006) FIXME: Parameter 'mode' implicitly has an 'any' type.
  selectDailyMode(mode) {
    return {
      type: `compare_${actionTypes.SELECT_DAILY_MODE}`,
      mode,
    }
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
  save: (data) => ({
    type: actionTypes.SAVE,
    result: dataParser(data),
  }),
}
