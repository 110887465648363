// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import { actionTypes as asyncDataFetchActionTypes } from '@bufferapp/async-data-fetch'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import keyWrapper from '@bufferapp/keywrapper'

export const actionTypes = keyWrapper('CAMPAIGN_POSTS_METRICS', {
  FETCH: 'FETCH',
  SELECT_NEW_METRIC: 'SELECT_NEW_METRIC',
  OPEN_MODAL: 'OPEN_MODAL',
  CLOSE_MODAL: 'CLOSE_MODAL',
})

const initialState = {
  loading: true,
  hasError: false,
  selectedMetric: '',
  totals: [],
  dailyMetrics: [],
  dailyPosts: [],
  postsForModal: null,
}

// @ts-expect-error TS(7006) FIXME: Parameter 'day' implicitly has an 'any' type.
export function getPostsForDay(day, allPosts) {
  // @ts-expect-error TS(7006) FIXME: Parameter 'post' implicitly has an 'any' type.
  return allPosts.find((post) => post.day === day)
}

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
export default (state = initialState, action) => {
  switch (action.type) {
    case `campaign_posts_metrics_${asyncDataFetchActionTypes.FETCH_START}`:
      return initialState
    case `campaign_posts_metrics_${asyncDataFetchActionTypes.FETCH_FAIL}`:
      return {
        ...initialState,
        loading: false,
        hasError: true,
      }
    case `campaign_posts_metrics_${asyncDataFetchActionTypes.FETCH_SUCCESS}`:
      return Object.assign({}, state, {
        loading: false,
        selectedMetric: 'Total Followers',
        totals: action.result.totals,
        dailyMetrics: action.result.dailyMetrics,
        dailyPosts: action.result.dailyPosts,
      })
    case actionTypes.SELECT_NEW_METRIC:
      return Object.assign({}, state, {
        selectedMetric: action.newMetric,
      })
    case actionTypes.OPEN_MODAL:
      return Object.assign({}, state, {
        postsForModal: getPostsForDay(action.day, state.dailyPosts),
      })
    case actionTypes.CLOSE_MODAL:
      return Object.assign({}, state, {
        postsForModal: null,
      })
    default:
      return state
  }
}

export const actions = {
  fetch: () => ({
    type: actionTypes.FETCH,
  }),
  // @ts-expect-error TS(7006) FIXME: Parameter 'newMetric' implicitly has an 'any' type... Remove this comment to see the full error message
  selectNewMetric: (newMetric) => ({
    type: actionTypes.SELECT_NEW_METRIC,
    newMetric,
  }),
  // @ts-expect-error TS(7006) FIXME: Parameter 'day' implicitly has an 'any' type.
  openModal: (day) => ({
    type: actionTypes.OPEN_MODAL,
    day,
  }),
  closeModal: () => ({
    type: actionTypes.CLOSE_MODAL,
  }),
}
