import React from 'react'
import PropTypes from 'prop-types'
import reactDOM from 'react-dom/server'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import ReactHighcharts from 'react-highcharts'
import styled from 'styled-components'
import getChartConfig, {
  getMarkerFillColor,
} from '~/shared-components/utils/chartUtils/chartConfig'
import dayjs from 'dayjs'
import { ReferralIcon } from '~/shared-components'
import { ChartTooltip } from '../ChartTooltip'
import {
  facebook,
  facebookDark,
  instagram,
  instagramDark,
  twitter,
  twitterDark,
  linkedin,
  linkedinDark,
} from '@bufferapp/ui/style/colors'

// @ts-expect-error TS(7034) FIXME: Variable 'chartReflow' implicitly has type 'any' i... Remove this comment to see the full error message
let chartReflow

const colorMap = {
  facebook,
  twitter,
  instagram,
  linkedin,
}

const lineColorMap = {
  facebook: facebookDark,
  twitter: twitterDark,
  instagram: instagramDark,
  linkedin: linkedinDark,
}

const IconWrapper = styled.span`
  border-radius: 50%;
  background-color: ${(props) =>
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    colorMap[props.name]};
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${({
    // @ts-expect-error TS(2339) FIXME: Property 'visible' does not exist on type 'Omit<De... Remove this comment to see the full error message
    visible,
  }) => (visible ? 1 : 0.5)};
  transition: all 150ms ease-in;

  &:hover {
    transform: scale(1.3);
  }
`

const Border = styled.span`
  border-radius: 50%;
  background-color: white;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: border 150ms ease-in;
  border: 2px solid
    ${({
      // @ts-expect-error TS(2339) FIXME: Property 'name' does not exist on type 'Omit<Detai... Remove this comment to see the full error message
      name,
      // @ts-expect-error TS(2339) FIXME: Property 'visible' does not exist on type 'Omit<De... Remove this comment to see the full error message
      visible,
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    }) => (visible ? colorMap[name] : 'transparent')};
`

const Wrapper = styled.div`
  .highcharts-legend-title {
    left: -65px !important;
    top: 30px !important;
  }
  .highcharts-legend {
    top: -6px !important;
  }
`

// @ts-expect-error TS(7006) FIXME: Parameter 'day' implicitly has an 'any' type.
function getTimeStamp(day) {
  return dayjs.utc(Number(day)).startOf('day').valueOf()
}

// @ts-expect-error TS(7006) FIXME: Parameter 'dailyMetrics' implicitly has an 'any' t... Remove this comment to see the full error message
function getSeriesData(dailyMetrics, index) {
  // @ts-expect-error TS(7034) FIXME: Variable 'innerSeries' implicitly has type 'any[]'... Remove this comment to see the full error message
  const innerSeries = []
  // @ts-expect-error TS(7006) FIXME: Parameter 'x' implicitly has an 'any' type.
  dailyMetrics.map((x) => {
    innerSeries.push({
      y: x.metrics[index].value,
      x: getTimeStamp(x.day),
      channel: x.metrics[index].label,
    })
  })
  // @ts-expect-error TS(7005) FIXME: Variable 'innerSeries' implicitly has an 'any[]' t... Remove this comment to see the full error message
  return innerSeries
}

// @ts-expect-error TS(7006) FIXME: Parameter 'dailyMetrics' implicitly has an 'any' t... Remove this comment to see the full error message
function prepareSeries(dailyMetrics) {
  const series = []

  for (let i = 0; i < dailyMetrics[0].metrics.length; i++) {
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const color = colorMap[dailyMetrics[0].metrics[i].label]
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const lineColor = lineColorMap[dailyMetrics[0].metrics[i].label]
    series.push({
      marker: {
        fillColor: getMarkerFillColor(color),
        lineColor: color,
      },
      fillColor: color,
      lineColor,
      color,
      data: getSeriesData(dailyMetrics, i),
      states: {},
      name: dailyMetrics[0].metrics[i].label,
      animation: {
        duration: 600,
      },
    })
  }

  return series
}

// @ts-expect-error TS(7006) FIXME: Parameter 'selectedMetric' implicitly has an 'any'... Remove this comment to see the full error message
function getTooltip(selectedMetric) {
  return {
    shared: true,
    crosshairs: true,
    formatter() {
      return reactDOM.renderToStaticMarkup(
        <ChartTooltip
          // @ts-expect-error TS(2322) FIXME: Type '{ day: any; data: any; selectedMetric: any; ... Remove this comment to see the full error message
          day={this.x}
          // @ts-expect-error TS(2339) FIXME: Property 'points' does not exist on type '{ shared... Remove this comment to see the full error message
          data={this.points}
          selectedMetric={selectedMetric}
        />,
      )
    },
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    borderRadius: 7,
    borderWidth: 0,
    shadow: false,
    useHTML: true,
  }
}

function getLegend() {
  return {
    align: 'right',
    verticalAlign: 'top',
    layout: 'horizontal',
    floating: true,
    symbolPadding: 0,
    symbolWidth: 0.1,
    symbolHeight: 0.1,
    symbolRadius: 0,
    useHTML: true,
    itemDistance: 6,
    title: {
      text: 'Channel',
      style: {
        fontFamily: 'Roboto, sans-serif',
        fontSize: '14px',
        fontWeight: '500',
        color: '#3D3D3D',
      },
    },
    labelFormatter: function () {
      return reactDOM.renderToStaticMarkup(
        // @ts-expect-error TS(2769) FIXME: No overload matches this call.
        <Border visible={this.visible} name={this.name}>
          {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
          <IconWrapper visible={this.visible} name={this.name}>
            <ReferralIcon
              item={{
                // @ts-expect-error TS(2339) FIXME: Property 'name' does not exist on type '{ align: s... Remove this comment to see the full error message
                source: this.name,
              }}
              size="small"
            />
          </IconWrapper>
        </Border>,
      )
    },
  }
}

// @ts-expect-error TS(7006) FIXME: Parameter 'tooltipWidth' implicitly has an 'any' t... Remove this comment to see the full error message
function tooltipPositioner(tooltipWidth, height, plotInfo) {
  // @ts-expect-error TS(2683) FIXME: 'this' implicitly has type 'any' because it does n... Remove this comment to see the full error message
  const { plotWidth } = this.chart
  const { plotX } = plotInfo
  const rightBorder = plotX + tooltipWidth
  const x = rightBorder > plotWidth ? plotWidth - (tooltipWidth / 3) * 2 : plotX

  return { x, y: 100 }
}

// @ts-expect-error TS(7006) FIXME: Parameter 'type' implicitly has an 'any' type.
function prepareData(type, selectedMetric, dailyData) {
  const config = getChartConfig(
    // @ts-expect-error TS(2345) FIXME: Argument of type '{ shared: boolean; crosshairs: b... Remove this comment to see the full error message
    getTooltip(selectedMetric),
    true,
    type,
    getLegend(),
    350,
  )

  // This is used to make space for the legend
  config.chart.spacingTop += 32
  // This is used to ensure we have space for the posts legend
  config.chart.spacingLeft += 11

  // This prevent the tooltip from interfering with the legend
  // @ts-expect-error TS(2339) FIXME: Property 'positioner' does not exist on type '() =... Remove this comment to see the full error message
  config.tooltip.positioner = tooltipPositioner

  // @ts-expect-error TS(7006) FIXME: Parameter 'x' implicitly has an 'any' type.
  const currentMetric = dailyData.find((x) => x.metric === selectedMetric)

  // @ts-expect-error TS(2322) FIXME: Type '{ marker: { fillColor: any; lineColor: any; ... Remove this comment to see the full error message
  config.series = prepareSeries(currentMetric.dailyValues)

  return config
}

export class StackedChart extends React.PureComponent {
  // there seems to be a problem with react-highcharts and the animations for column charts
  // so I found this hack in this github issue: https://github.com/kirjs/react-highcharts/issues/171#issuecomment-268418008
  componentDidUpdate() {
    // @ts-expect-error TS(2339) FIXME: Property 'getChart' does not exist on type 'ReactI... Remove this comment to see the full error message
    const chart = this.refs.chart ? this.refs.chart.getChart() : {}
    // @ts-expect-error TS(7005) FIXME: Variable 'chartReflow' implicitly has an 'any' typ... Remove this comment to see the full error message
    chartReflow = chartReflow || chart.reflow
    chart.reflow = () => {}
    // @ts-expect-error TS(7005) FIXME: Variable 'chartReflow' implicitly has an 'any' typ... Remove this comment to see the full error message
    setTimeout(() => (chart.reflow = chartReflow))
  }

  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'type' does not exist on type 'Readonly<{... Remove this comment to see the full error message
    const { type, selectedMetric, dailyData } = this.props
    const chartOptions = prepareData(type, selectedMetric, dailyData)

    return (
      <Wrapper>
        <ReactHighcharts config={chartOptions} ref="chart" />
      </Wrapper>
    )
  }
}

// @ts-expect-error TS(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
StackedChart.propTypes = {
  selectedMetric: PropTypes.string,
}
