// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import { actionTypes as asyncDataFetchActionTypes } from '@bufferapp/async-data-fetch'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import keyWrapper from '@bufferapp/keywrapper'
import { dataParser } from './query'

export const actionTypes = keyWrapper('ANSWERS_SUMMARY', {
  SAVE: 'SAVE',
})

const initialState = {
  loading: true,
  hasError: false,
  day: null,
  type: null,
  frequency: null,
}

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
export default (state = initialState, action) => {
  switch (action.type) {
    case `answers_${asyncDataFetchActionTypes.FETCH_START}`:
      return initialState
    case actionTypes.SAVE:
    case `answers_${asyncDataFetchActionTypes.FETCH_SUCCESS}`:
      return {
        ...state,
        loading: false,
        day: action.result.day,
        type: action.result.type,
        frequency: action.result.frequency,
      }
    case `answers_${asyncDataFetchActionTypes.FETCH_FAIL}`:
      return {
        ...initialState,
        loading: false,
        hasError: true,
      }
    default:
      return state
  }
}

export const actions = {
  // @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
  save: (data) => ({
    type: actionTypes.SAVE,
    result: { ...dataParser(data) },
  }),
}
