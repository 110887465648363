import React, { useEffect, memo } from 'react'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@loa... Remove this comment to see the full error message
import loadable from '@loadable/component'
import { Redirect, Route, Switch } from 'react-router'
import { push } from 'connected-react-router'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import NavSidebar from '~/nav-sidebar'
import ProfileSelector from '~/profile-selector'
import DatePicker from '~/date-picker'
import ProfileLoader from '~/profile-loader'
import ExportPicker from '~/export-picker'
import {
  ContentCard,
  LayoutContents,
  LayoutLeft,
  LayoutPage,
  LayoutRight,
  LayoutSidebar,
  LayoutToolbar,
  ShopifyProfile,
  Toolbar,
  ToolbarItem,
  ToolbarLeft,
  ToolbarRight,
  Watermark,
} from '~/shared-components'
import { Loader } from '@bufferapp/components'
import { useAccount } from '~/account'
import PostsTab from '../PostsTab'
import OverviewTab from '../OverviewTab'
import AnswersTab from '../AnswersTab'
import AudienceTab from '../AudienceTab'
import Subtabs from '../Subtabs'
import ShopifyOverview from '../ShopifyOverview'
import BackfillingState from '~/backfilling-state'
import Notice from '@bufferapp/ui/Notice'
import Text from '@bufferapp/ui/Text'
import styled from 'styled-components'

const NoticeWrapper = styled.div`
  margin: 0px 0px 16px;
`

// @ts-expect-error TS(1323) FIXME: Dynamic imports are only supported when the '--mod... Remove this comment to see the full error message
const Stories = loadable(() => import('../Stories'), {
  fallback: <Loader />,
})

function getNetworkToDisableForFeature(tabId: string): string {
  switch (tabId) {
    case 'audience':
      return 'twitter'
    default:
      return ''
  }
}

const FacebookNotice = memo(function facebookNoticeFunction(): JSX.Element {
  return (
    <NoticeWrapper>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-expect-error TS(2741) FIXME: Property 'className' is missing in type '{ children: ... Remove this comment to see the full error message */}
      <Notice type="warning">
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-expect-error TS(2741) FIXME: Property 'className' is missing in type '{ children: ... Remove this comment to see the full error message */}
        <Text>
          Due to recent updates from Meta, we are no longer able to ingest data
          for number of engaged users, and gender or age demographics for your
          Facebook Page.
        </Text>
      </Notice>
    </NoticeWrapper>
  )
})

const SingleProfilePage = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'selectedProfile' implicitly has a... Remove this comment to see the full error message
  selectedProfile,
  match: {
    // @ts-expect-error TS(7031) FIXME: Binding element 'channel' implicitly has an 'any' ... Remove this comment to see the full error message
    params: { channel, id, tabId },
  },
  // @ts-expect-error TS(7031) FIXME: Binding element 'location' implicitly has an 'any'... Remove this comment to see the full error message
  location,
  // @ts-expect-error TS(7031) FIXME: Binding element 'changeChannelPage' implicitly has... Remove this comment to see the full error message
  changeChannelPage,
  // @ts-expect-error TS(7031) FIXME: Binding element 'navSidebar' implicitly has an 'an... Remove this comment to see the full error message
  navSidebar,
}) => {
  const account = useAccount()

  useEffect(() => {
    const channelInfo = navSidebar[`${channel}Profile`]
    const hasChannels = !!navSidebar.channels.length

    if (hasChannels && !channelInfo) {
      changeChannelPage(`/connect/${channel}`)
    } else if (hasChannels && channelInfo.id !== id) {
      changeChannelPage(`/${channel}/${tabId}/${channelInfo.id}`)
    }
  }, [account, navSidebar.channels.length])

  return (
    <LayoutPage>
      <LayoutSidebar>
        {/* @ts-expect-error TS(2322) FIXME: Type '{ route: any; tabId: any; }' is not assignab... Remove this comment to see the full error message */}
        <NavSidebar route={location.pathname} tabId={tabId} />
      </LayoutSidebar>
      <LayoutLeft />
      <ProfileLoader>
        <LayoutToolbar>
          <Toolbar>
            <ToolbarLeft>
              {selectedProfile && selectedProfile.service !== 'shopify' && (
                <ProfileSelector
                  // @ts-expect-error TS(2322) FIXME: Type '{ disableNetwork: string; }' is not assignab... Remove this comment to see the full error message
                  disableNetwork={getNetworkToDisableForFeature(tabId)}
                />
              )}
              {selectedProfile && selectedProfile.service === 'shopify' && (
                <ShopifyProfile profile={selectedProfile} />
              )}
            </ToolbarLeft>
            <ToolbarRight>
              <ToolbarItem>
                <DatePicker />
              </ToolbarItem>
              <ToolbarItem>
                {/* @ts-expect-error TS(2322) FIXME: Type '{ filename: string; }' is not assignable to ... Remove this comment to see the full error message */}
                <ExportPicker filename={`buffer-${tabId}-analytics`} />
              </ToolbarItem>
            </ToolbarRight>
          </Toolbar>
        </LayoutToolbar>
        <LayoutContents>
          <ContentCard subtabs={<Subtabs />}>
            {selectedProfile && selectedProfile.service === 'facebook' && (
              <FacebookNotice />
            )}
            {/* @ts-expect-error TS(2746) FIXME: This JSX tag's 'children' prop expects a single ch... Remove this comment to see the full error message */}
            <BackfillingState>
              <Switch>
                <Route
                  path="/shopify/overview/:id?"
                  component={ShopifyOverview}
                />
                <Route
                  path="/:channel?/overview/:id?"
                  component={OverviewTab}
                />
                <Route path="/:channel?/posts/:id?" component={PostsTab} />
                <Route path="/:channel?/answers/:id?" component={AnswersTab} />
                <Route
                  path="/:channel?/audience/:id?"
                  component={AudienceTab}
                />
                <Route path="/:channel?/stories/:id?" component={Stories} />
                <Redirect from="*" to={'/'} />
              </Switch>
              <Watermark />
            </BackfillingState>
          </ContentCard>
        </LayoutContents>
      </ProfileLoader>
      <LayoutRight />
    </LayoutPage>
  )
}

SingleProfilePage.propTypes = {
  selectedProfile: PropTypes.shape({
    user: PropTypes.string,
    service: PropTypes.string,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      tabId: PropTypes.string,
    }),
  }).isRequired,
}

SingleProfilePage.defaultProps = {
  selectedProfile: null,
}

export default connect(
  (state) => ({
    // @ts-expect-error TS(2339) FIXME: Property 'profiles' does not exist on type 'Defaul... Remove this comment to see the full error message
    selectedProfile: state.profiles.selectedProfile,
    // @ts-expect-error TS(2339) FIXME: Property 'navSidebar' does not exist on type 'Defa... Remove this comment to see the full error message
    navSidebar: state.navSidebar,
  }),
  (dispatch) => ({
    // @ts-expect-error TS(7006) FIXME: Parameter 'uri' implicitly has an 'any' type.
    changeChannelPage: (uri) => dispatch(push(uri)),
  }),
)(SingleProfilePage)
