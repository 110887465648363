import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Text } from '@bufferapp/components'

import ShopifyLogo from '../ShopifyLogo'

const ProfileHolder = styled.div`
  display: flex;
  align-items: center;
`

// @ts-expect-error TS(7031) FIXME: Binding element 'profile' implicitly has an 'any' ... Remove this comment to see the full error message
const ShopifyProfile = ({ profile }) => (
  <ProfileHolder>
    <ShopifyLogo />
    <Text color="black" size="mini" weight="medium">
      {profile.username}
    </Text>
  </ProfileHolder>
)

ShopifyProfile.propTypes = {
  profile: PropTypes.shape({
    username: PropTypes.string,
  }).isRequired,
}

const ProfileIconStyled = styled(ShopifyProfile)`
  position: relative;
`

export default ProfileIconStyled
