import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import ReactHighmaps from 'react-highcharts/ReactHighmaps'
import styled from 'styled-components'
import { DemographicTable } from '~/shared-components'

import getChartConfig from './chartConfig'
import countrieNames from './countries'

// @ts-expect-error TS(7006) FIXME: Parameter 'metrics' implicitly has an 'any' type.
function getSelectedMetricsGroup(metrics, selectedGroup) {
  // @ts-expect-error TS(7006) FIXME: Parameter 'group' implicitly has an 'any' type.
  return metrics.find((group) => group.key === selectedGroup)
}

const ChartContainer = styled.div`
  padding: 0 16px;
`

class Chart extends PureComponent {
  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'selectedGroup' does not exist on type 'R... Remove this comment to see the full error message
    const { selectedGroup, metrics } = this.props
    const group = getSelectedMetricsGroup(metrics, selectedGroup)
    const groupMetrics = group.metrics
      // @ts-expect-error TS(7006) FIXME: Parameter 'metric' implicitly has an 'any' type.
      .find((metric) => metric.key === 'country')
      .values.slice(0, 3)
    return (
      <div>
        <ChartContainer>
          {/* @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 2. */}
          <ReactHighmaps config={getChartConfig(group, selectedGroup)} />
        </ChartContainer>
        <DemographicTable
          // @ts-expect-error TS(7006) FIXME: Parameter 'country' implicitly has an 'any' type.
          metrics={groupMetrics.map((country) => {
            return {
              ...country,
              // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
              label: countrieNames[country.label],
            }
          })}
          labels={['Countries', group.label]}
        />
      </div>
    )
  }
}

// @ts-expect-error TS(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
Chart.propTypes = {
  metrics: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedGroup: PropTypes.string.isRequired,
}

export default Chart
