import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { middleware as appSidebarMiddleware } from '~/app-sidebar'
import { middleware as i18nMiddleware } from '~/i18n'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import { middleware as asyncDataFetchMiddleware } from '@bufferapp/async-data-fetch'
import { middleware as summaryMiddleware } from '~/summary-table'
import { middleware as datePickerMiddleware } from '~/date-picker'
import { middleware as postsSummaryMiddleware } from '~/posts-summary-table'
import { middleware as averageMiddleware } from '~/average-table'
import { middleware as exportToPNGMiddleware } from '~/png-export'
import { middleware as exportToCSVMiddleware } from '~/csv-export'
import { middleware as postsMiddleware } from '~/posts-table'
import { middleware as compareChartMiddleware } from '~/compare-chart'
import { middleware as addReportMiddleware } from '~/add-report'
import { middleware as reportListMiddleware } from '~/report-list'
import { middleware as profileSelectorMiddleware } from '~/profile-selector'
import { middleware as environmentMiddleware } from '~/environment'
import { middleware as exportToPDFMiddleware } from '~/pdf-export'
import { middleware as accountMiddleware } from '~/account'
import { middleware as hashtagsMiddleware } from '~/hashtags-table'
import { middleware as demographicMiddleware } from '~/demographic-store'
import { middleware as demographicOverviewMiddleware } from '~/demographic-overview'
import { middleware as demographicGenderAgeMiddleware } from '~/demographic-gender-age'
import { middleware as demographicCitiesMiddleware } from '~/demographic-cities'
import { middleware as demographicCountriesMiddleware } from '~/demographic-countries'
import { middleware as answersMiddleware } from '~/answers-summary'
import { middleware as analyticsMiddleware } from '~/analytics-middleware'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'redu... Remove this comment to see the full error message
import dynamicMiddlewares from 'redux-dynamic-middlewares'
import initMiddleware from './initMiddleware'
import { middleware as storiesSummaryMiddleware } from '~/stories-summary-table'

import reducers from './reducers'

export const history = createBrowserHistory()

const createReducer = function (asyncReducers = {}) {
  return combineReducers({
    router: connectRouter(history),
    ...reducers,
    ...asyncReducers,
  })
}

// @ts-expect-error TS(7006) FIXME: Parameter 'initialstate' implicitly has an 'any' t... Remove this comment to see the full error message
const configureStore = (initialstate) => {
  const composeEnhancers =
    // @ts-expect-error TS(2339) FIXME: Property '__REDUX_DEVTOOLS_EXTENSION_COMPOSE__' do... Remove this comment to see the full error message
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? // @ts-expect-error TS(2339) FIXME: Property '__REDUX_DEVTOOLS_EXTENSION_COMPOSE__' do... Remove this comment to see the full error message
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose

  const store = createStore(
    createReducer(),
    initialstate,
    composeEnhancers(
      applyMiddleware(
        initMiddleware,
        analyticsMiddleware,
        routerMiddleware(history),
        asyncDataFetchMiddleware,
        i18nMiddleware,
        appSidebarMiddleware,
        datePickerMiddleware,
        summaryMiddleware,
        postsSummaryMiddleware,
        averageMiddleware,
        postsMiddleware,
        hashtagsMiddleware,
        compareChartMiddleware,
        addReportMiddleware,
        reportListMiddleware,
        profileSelectorMiddleware,
        environmentMiddleware,
        accountMiddleware,
        demographicMiddleware,
        demographicOverviewMiddleware,
        demographicGenderAgeMiddleware,
        demographicCitiesMiddleware,
        demographicCountriesMiddleware,
        answersMiddleware,
        dynamicMiddlewares,
        storiesSummaryMiddleware,

        // This need to be the last middlewares in the chain
        exportToPDFMiddleware,
        exportToPNGMiddleware,
        exportToCSVMiddleware,
      ),
    ),
  )

  // @ts-expect-error TS(2339) FIXME: Property 'asyncReducers' does not exist on type 'S... Remove this comment to see the full error message
  store.asyncReducers = {}

  // @ts-expect-error TS(2339) FIXME: Property 'injectReducer' does not exist on type 'S... Remove this comment to see the full error message
  store.injectReducer = function (key, reducer) {
    // @ts-expect-error TS(2339) FIXME: Property 'asyncReducers' does not exist on type 'S... Remove this comment to see the full error message
    store.asyncReducers[key] = reducer
    // @ts-expect-error TS(2339) FIXME: Property 'asyncReducers' does not exist on type 'S... Remove this comment to see the full error message
    store.replaceReducer(createReducer(store.asyncReducers))
  }

  return store
}

// @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
export default configureStore()
