import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { grayLighter } from '@bufferapp/ui/style/colors'
import { useSplitEnabled } from '@bufferapp/features'
import Item from './Item'
import Channels from './Channels'
import AccountManagement from './AccountManagement'
import { UserIsAdmin } from '~/account'
import SidebarHeader from '@bufferapp/ui/SidebarHeader'

const Container = styled.div`
  width: 200px;
  height: 100%;
`

const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.75rem 0 0.75rem 0.75rem;
  height: 100%;
  width: 100%;
  background: ${grayLighter};
  box-sizing: border-box;
`

const bottomSectionStyle = {
  marginTop: 'auto',
}

const Group = styled.div`
  margin: 0 0 1.5rem;
`

const Section = styled.div`
  div:first-child {
    margin-bottom: 8px;
  }
`

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const NavSidebar = (props) => {
  const { isEnabled: isCampaignsToTagsEnabled } =
    useSplitEnabled('campaigns-to-tags')

  const campaignsUrl = isCampaignsToTagsEnabled ? '/tags/' : '/campaigns/'

  return (
    <Container>
      <Sidebar className="sidebar">
        <Group>
          <Item href="/" {...props} service={'home'}>
            Home
          </Item>
        </Group>
        <Channels {...props} />
        {/* @ts-expect-error TS(2741) FIXME: Property 'hasPageContent' is missing in type '{ ch... Remove this comment to see the full error message */}
        <UserIsAdmin>
          <Section>
            <SidebarHeader title="Tools" />
            <Item
              href={campaignsUrl}
              isNew={isCampaignsToTagsEnabled}
              {...props}
              service={'tags'}
            >
              {isCampaignsToTagsEnabled ? 'Tags' : 'Campaigns'}
            </Item>
            <Item href="/reports/" {...props} service={'reports'}>
              Reports
            </Item>
          </Section>
        </UserIsAdmin>
        <div style={bottomSectionStyle}>
          <AccountManagement {...props} />
        </div>
      </Sidebar>
    </Container>
  )
}

NavSidebar.propTypes = {
  channels: PropTypes.arrayOf(
    PropTypes.shape({
      service: PropTypes.string,
    }),
  ).isRequired,
}

// @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
const mapStateToProps = (state) => ({
  channels: state.navSidebar.channels,
  selectedProfileId: state.profiles.selectedProfile
    ? state.profiles.selectedProfile.id
    : '',
})

export default connect(mapStateToProps)(NavSidebar)
