import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Text, lightSlate, nevada, white } from '@bufferapp/components'
import { PostMedia } from '~/shared-components'
import Metrics from './components/Metrics'
import Button from '@bufferapp/ui/Button'
import Profile from './components/Profile'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import dayTimezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(dayTimezone)

const Container = styled.li`
  display: flex;
  height: 14rem;
  max-height: 178px;
  list-style: none;
  padding: 16px;
  background: ${white};
  border-bottom: 1px solid #cfd7df;

  &:last-of-type {
    border-bottom: none;
  }
`

const Left = styled.div`
  flex: 0;
  width: 178px;
  height: 178px;
  position: relative;
`

const Right = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Top = styled.div`
  flex: 0;
  padding: 0.5rem 1rem;
  height: calc(100% - 98px);
  box-sizing: border-box;
`

const Bottom = styled.div`
  flex: 0;
  padding: 0.5rem 1rem;
  border-left: 1px solid #ededed;
  height: 78px;
  box-sizing: border-box;
`

const ContentDate = styled.div`
  color: ${nevada};
  margin-bottom: 0.5rem;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const ContentText = styled.span`
  color: ${lightSlate};

  a,
  a:link,
  a:visited,
  a:hover {
    color: ${lightSlate};
    text-decoration: none;
  }
`

const ContentMain = styled.div`
  position: relative;
  padding: 0 10rem 0 0;
  line-height: 1.6em;
  height: 4.8em;
  overflow: hidden;

  &:after {
    content: '';
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 70%;
    height: 1.3em;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1) 50%
    );
  }
`

const ViewPost = styled.span`
  position: absolute;
  top: 1px;
  left: 1px;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 2px;
  opacity: 1;
  transition: opacity 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955);

  &:hover {
    opacity: 1;
  }
`

const PostMediaContainer = styled.div`
  width: 178px;
  height: 178px;
`

class PostItem extends React.Component {
  // @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
  constructor(props) {
    super(props)
    this.state = {
      isHovered: false,
    }
  }

  handleMouseEnter = () => {
    this.setState({ isHovered: true })
  }

  handleMouseLeave = () => {
    this.setState({ isHovered: false })
  }

  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'post' does not exist on type 'Readonly<{... Remove this comment to see the full error message
    let { post, config, timezone, exporting, forReport, onViewPost } =
      this.props

    if (!timezone) {
      timezone = dayjs.tz.guess()
    }

    // @ts-expect-error TS(7006) FIXME: Parameter 'metric' implicitly has an 'any' type.
    const metrics = config.map((metric) => ({
      ...metric,
      value: post.statistics[metric.key] || 0,
      paid: post.boosted_statistics
        ? post.boosted_statistics[metric.key]
        : null,
    }))

    const dateFormat = 'MMMM D, YYYY hh:mma'
    return (
      <Container
        onMouseEnter={() => this.handleMouseEnter()}
        onMouseLeave={() => this.handleMouseLeave()}
        className="post-item"
      >
        <Left>
          <PostMediaContainer>
            <PostMedia post={post} />
          </PostMediaContainer>
          {!exporting && !forReport && onViewPost && (
            // @ts-expect-error TS(2339) FIXME: Property 'isHovered' does not exist on type 'Reado... Remove this comment to see the full error message
            <ViewPost style={{ opacity: this.state.isHovered ? 1 : 0 }}>
              {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; size: string... Remove this comment to see the full error message */}
              <Button
                type="secondary"
                size="small"
                onClick={() => {
                  onViewPost(post)
                }}
                label="View post"
              />
            </ViewPost>
          )}
        </Left>
        <Right>
          <Top>
            <ContentDate>
              <Text size="medium" weight="bold" color="outerSpace">
                {dayjs(post.date).tz(timezone).format(dateFormat)}
              </Text>
              {post.profile && <Profile profile={post.profile} />}
            </ContentDate>
            <ContentMain>
              <Text size="mini">
                <ContentText>{post.text}</ContentText>
              </Text>
            </ContentMain>
          </Top>
          <Bottom>
            <Metrics metrics={metrics} />
          </Bottom>
        </Right>
      </Container>
    )
  }
}

// @ts-expect-error TS(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
PostItem.defaultProps = {
  onViewPost: null,
  forReport: false,
}

// @ts-expect-error TS(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
PostItem.propTypes = {
  post: PropTypes.shape({
    date: PropTypes.number,
    id: PropTypes.string,
    media: PropTypes.shape({
      picture: PropTypes.string,
      thumbnail: PropTypes.string,
    }),
    profile: PropTypes.shape({
      service: PropTypes.string,
      username: PropTypes.string,
    }),
    serviceLink: PropTypes.string,
    statistics: PropTypes.shape({
      comments: PropTypes.number,
      postClicks: PropTypes.number,
      postImpressions: PropTypes.number,
      postReach: PropTypes.number,
      reactions: PropTypes.number,
      shares: PropTypes.number,
    }),
    text: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  config: PropTypes.arrayOf(PropTypes.object).isRequired,
  timezone: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  exporting: PropTypes.bool.isRequired,
  forReport: PropTypes.bool,
  onViewPost: PropTypes.func,
}

export default PostItem
