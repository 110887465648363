import React from 'react'
import reactDOM from 'react-dom/server'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Select from '@bufferapp/ui/Select'
import Text from '@bufferapp/ui/Text'
import { ChevronDown } from '@bufferapp/ui/Icon'
import { useSplitEnabled } from '@bufferapp/features'
import { ColoredIcon } from '~/shared-components'

const Selector = styled.button`
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  background: transparent;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: transform 100ms ease-out;
  outline: none;

  h2 {
    margin: 0 0.25rem 0 0;
    max-width: 800px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`

// @ts-expect-error TS(7031) FIXME: Binding element 'campaigns' implicitly has an 'any... Remove this comment to see the full error message
const CampaignSelector = ({ campaigns, selectedCampaign, onSelect }) => {
  const { isEnabled: isCampaignsToTagsEnabled } =
    useSplitEnabled('campaigns-to-tags')

  // @ts-expect-error TS(7006) FIXME: Parameter 'campaignItems' implicitly has an 'any' ... Remove this comment to see the full error message
  const mapCampaignsToItems = (campaignItems) =>
    // @ts-expect-error TS(7006) FIXME: Parameter 'campaign' implicitly has an 'any' type.
    campaignItems.map((campaign) => ({
      ...campaign,
      title: campaign.name,
      // @ts-expect-error TS(7031) FIXME: Binding element 'color' implicitly has an 'any' ty... Remove this comment to see the full error message
      component: ({ color }) =>
        reactDOM.renderToStaticMarkup(<ColoredIcon color={color} />),
    }))

  // @ts-expect-error TS(7006) FIXME: Parameter 'onButtonClick' implicitly has an 'any' ... Remove this comment to see the full error message
  const renderSelector = (onButtonClick) => (
    <Selector onClick={onButtonClick}>
      {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
      <ColoredIcon large color={selectedCampaign.color} />
      <Text type="h2" color="black">
        {selectedCampaign.name}
      </Text>
      <ChevronDown />
    </Selector>
  )

  return (
    <React.Fragment>
      {selectedCampaign && (
        <Select
          // @ts-expect-error TS(7006) FIXME: Parameter 'onButtonClick' implicitly has an 'any' ... Remove this comment to see the full error message
          customButton={(onButtonClick) => renderSelector(onButtonClick)}
          // @ts-expect-error TS(7006) FIXME: Parameter 'selectedItem' implicitly has an 'any' t... Remove this comment to see the full error message
          onSelectClick={(selectedItem) => {
            onSelect(selectedItem, isCampaignsToTagsEnabled)
            return false
          }}
          items={mapCampaignsToItems(campaigns)}
          hideSearch
        />
      )}
    </React.Fragment>
  )
}

CampaignSelector.defaultProps = {
  selectedCampaign: null,
  campaigns: [],
  onSelect: () => {
    throw new Error('missing select handler')
  },
}

CampaignSelector.propTypes = {
  campaigns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      color: PropTypes.string,
      onSelect: PropTypes.func,
    }),
  ),
  selectedCampaign: PropTypes.shape({
    name: PropTypes.string,
    color: PropTypes.string,
  }),
  onSelect: PropTypes.func,
}

export default CampaignSelector
