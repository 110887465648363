import React from 'react'
import { Link } from '@bufferapp/components'
import SidebarListItem from '@bufferapp/ui/SidebarListItem'
import { Gear } from '@bufferapp/ui/Icon'
import { channelsRedirectUrl } from '~/account'

const AccountManagement = (): JSX.Element => {
  return (
    <Link unstyled href={`${channelsRedirectUrl()}`}>
      <SidebarListItem
        id={'manage-accounts'}
        title={'Manage Channels'}
        icon={<Gear />}
        onItemClick={(): void => undefined}
      />
    </Link>
  )
}

export default AccountManagement
