import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Text from '@bufferapp/ui/Text'
import { outerSpace } from '@bufferapp/components'
import SocialIcon from '../../../../SocialIcon'

const ProfileHolder = styled.span`
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: ${outerSpace};
`

interface ProfileProps {
  profile: any
}

const Profile = ({ profile }: ProfileProps): JSX.Element => {
  return (
    <ProfileHolder>
      <SocialIcon service={profile.service} socialIconSize={20} inline={true} />
      <Text type="span">{profile.username}</Text>
    </ProfileHolder>
  )
}

Profile.propTypes = {
  profile: PropTypes.shape({
    service: PropTypes.string,
    username: PropTypes.string,
  }).isRequired,
}

export default Profile
