// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import { actionTypes as asyncDataFetchActionTypes } from '@bufferapp/async-data-fetch'

const initialState = {
  metrics: [],
  loading: true,
  hasError: false,
}

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
export default (state = initialState, action) => {
  switch (action.type) {
    case `demographic_${asyncDataFetchActionTypes.FETCH_START}`:
      return initialState
    case `demographic_${asyncDataFetchActionTypes.FETCH_SUCCESS}`:
      return {
        ...state,
        loading: false,
        metrics: action.result.metrics,
      }
    case `demographic_${asyncDataFetchActionTypes.FETCH_FAIL}`:
      return {
        ...initialState,
        loading: false,
        hasError: true,
      }
    default:
      return state
  }
}
