import React from 'react'
import styled from 'styled-components'
import { ProfileBadge } from '~/shared-components'
import Select from '@bufferapp/ui/Select'
import ChevronDown from '@bufferapp/ui/Icon/Icons/ChevronDown'
import { white, grayDark, grayDarker } from '@bufferapp/ui/style/colors'
import { fontFamily, lineHeight } from '@bufferapp/ui/style/fonts'
import type { Profile } from '~/utils/types'
import { renderToStaticMarkup } from 'react-dom/server'

const ButtonContainer = styled.div`
  height: 40px;
  background: ${white};
  border: 1px solid #b8b8b8;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  display: flex;
  width: 100%;

  :hover {
    cursor: pointer;
    border-color: ${grayDark};
  }
`

const LabelContainer = styled.div`
  width: 100%;
  display: flex;
`

const Title = styled.span`
  font-family: ${fontFamily};
  font-weight: 500;
  font-size: 14px;
  line-height: ${lineHeight};
  color: #596269;
  display: flex;
  align-items: center;
  padding: 0rem 1rem;
  gap: 12px;
  ${ButtonContainer}:hover & {
    color: ${grayDarker};
  }
`

const IconContainer = styled.span`
  height: 100%;
  padding: 12px 0;

  & svg {
    position: absolute;
    right: 16px;
  }
`

interface FormattedProfile extends Profile {
  selected: boolean
  onItemClick: () => void
}

interface ProfileSelectorDropdownProps {
  profiles: Profile[]
  selectedProfileId: string
  profilesFilterString: string
  selectProfile: (profile: Profile) => void
}

const ProfileSelectorDropdown = ({
  profiles,
  selectedProfileId,
  profilesFilterString,
  selectProfile,
}: ProfileSelectorDropdownProps): JSX.Element | null => {
  const renderButton = (onButtonClick: () => void): JSX.Element | null => {
    const selectedProfile = profiles.find((p) => p?.id === selectedProfileId)

    if (!selectedProfile) {
      return null
    }

    const content = (
      <React.Fragment>
        <LabelContainer>
          <Title>
            <ProfileBadge
              avatarUrl={selectedProfile.avatarUrl}
              service={selectedProfile.service}
            />
            <span>{selectedProfile.username}</span>
          </Title>
        </LabelContainer>
      </React.Fragment>
    )
    return (
      <ButtonContainer onClick={onButtonClick}>
        {content}
        <IconContainer>
          <ChevronDown />
        </IconContainer>
      </ButtonContainer>
    )
  }

  const filterProfilesByService = (
    profiles: Profile[],
    service: string,
  ): Profile[] => {
    return profiles.filter((p) => p.service === service)
  }

  const formatProfiles = (profiles: Profile[]): FormattedProfile[] => {
    return profiles.map((profile) => {
      return {
        ...profile,
        disabled: profile.id === selectedProfileId,
        selected: profile.id === selectedProfileId,
        onItemClick: () => selectProfile(profile),
        component: () =>
          renderToStaticMarkup(
            <ProfileBadge
              avatarUrl={profile.avatarUrl}
              service={profile.service}
            />,
          ),
      }
    })
  }

  const selectedProfile = profiles.find((p) => p.id === selectedProfileId)

  let filteredProfiles

  if (profiles.length && selectedProfile) {
    filteredProfiles = filterProfilesByService(
      profiles,
      selectedProfile.service,
    ).filter((p) => p.username.toLowerCase().match(profilesFilterString))
  }

  if (!filteredProfiles) {
    return null
  }

  const formattedProfiles = formatProfiles(filteredProfiles)

  return (
    <div style={{ width: '480px' }}>
      <Select
        // @ts-expect-error
        onSelectClick={(selectedItem: FormattedProfile): void =>
          selectedItem.onItemClick()
        }
        customButton={(onButtonClick: () => void): JSX.Element | null =>
          renderButton(onButtonClick)
        }
        label="Search Me"
        onSearchChange={(): boolean => true}
        type="primary"
        searchPlaceholder="Search profiles..."
        keyMap={{
          id: 'id',
          title: 'username',
        }}
        items={formattedProfiles}
        fullWidth
        capitalizeItemLabel={false}
      />
    </div>
  )
}

export default ProfileSelectorDropdown
