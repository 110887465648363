// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import { actionTypes as asyncDataFetchActionTypes } from '@bufferapp/async-data-fetch'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import keyWrapper from '@bufferapp/keywrapper'

const SOCIAL_SERVICES = ['instagram', 'facebook', 'twitter', 'linkedin']

// This function formats the data in the way the client was receiving it so far from the RPC method
// @ts-expect-error TS(7006) FIXME: Parameter 'channels' implicitly has an 'any' type.
const formatChannels = (channels, accountChannels) => {
  const sortedChannels = {
    social: [],
    nonSocial: [],
    accountChannels,
  }

  channels
    // @ts-expect-error TS(7006) FIXME: Parameter 'channel' implicitly has an 'any' type.
    .map((channel) => ({
      id: channel.id,
      avatarUrl: channel.avatar,
      service: channel.service,
      serviceId: channel.serviceId,
      username: channel.name,
      organizationId: channel.organizationId,
      disabled: channel.isLocked,
      invalid: channel.isDisconnected,
    }))
    // @ts-expect-error TS(7006) FIXME: Parameter 'channel' implicitly has an 'any' type.
    .map((channel) =>
      SOCIAL_SERVICES.includes(channel.service)
        ? // @ts-expect-error TS(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
          sortedChannels.social.push(channel)
        : // @ts-expect-error TS(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
          sortedChannels.nonSocial.push(channel),
    )

  return sortedChannels
}

export const actionTypes = keyWrapper('PROFILES', {})

const initialState = {
  loading: true,
  hasError: false,
}

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
export default (state = initialState, action) => {
  switch (action.type) {
    case `profiles_${asyncDataFetchActionTypes.FETCH_START}`:
      return {
        loading: true,
      }
    case `profiles_${asyncDataFetchActionTypes.FETCH_SUCCESS}`:
      return {
        loading: false,
      }
    case `profiles_${asyncDataFetchActionTypes.FETCH_FAIL}`:
      return {
        ...initialState,
        loading: false,
        hasError: true,
      }
    default:
      return state
  }
}

export const actions = {
  // @ts-expect-error TS(7006) FIXME: Parameter 'channels' implicitly has an 'any' type.
  storeChannel: (channels, accountChannels) => {
    return {
      type: `profiles_${asyncDataFetchActionTypes.FETCH_SUCCESS}`,
      result: formatChannels(channels, accountChannels),
    }
  },

  failedToFetchChannels: () => {
    return {
      type: `profiles_${asyncDataFetchActionTypes.FETCH_FAIL}`,
    }
  },

  fetchingChannels: () => {
    return {
      type: `profiles_${asyncDataFetchActionTypes.FETCH_START}`,
    }
  },
}
