// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import { actionTypes } from '@bufferapp/async-data-fetch'
import {
  actions as dateActions,
  actionTypes as dateActionTypes,
} from './reducer'

// @ts-expect-error TS(7006) FIXME: Parameter 'query' implicitly has an 'any' type.
const getStartDate = (query) => {
  let startDate = query.match(/start_date=(.*)&/)
  if (startDate) {
    startDate = parseInt(startDate[1], 10)
  }
  return startDate
}
// @ts-expect-error TS(7006) FIXME: Parameter 'query' implicitly has an 'any' type.
const getEndDate = (query) => {
  let endDate = query.match(/end_date=(.*)$/)
  if (endDate) {
    endDate = parseInt(endDate[1], 10)
  }
  return endDate
}

// @ts-expect-error TS(7031) FIXME: Binding element 'dispatch' implicitly has an 'any'... Remove this comment to see the full error message
export default ({ dispatch, getState }) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'next' implicitly has an 'any' type.
  (next) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
  (action) => {
    let startDate
    let endDate
    const state = getState()
    const result = action.result
    switch (action.type) {
      case dateActionTypes.SET_DATE_RANGE:
        // we don't want to trigger the event if the date is unchanged
        // this is useful to prevent fetching the same data muliple times
        if (
          action.startDate === state.date.startDate &&
          action.endDate === state.date.endDate &&
          // needed because we are changing start and end date with the custom picker open
          !state.date.calendarOpen
        ) {
          return null
        }

        // We don't want dispatch SET_DATE_RANGE event if we are missing profiles
        // or it will result in malformed requests
        if (state.profileLoader.loading) {
          return null
        }

        break
      case `get_report_${actionTypes.FETCH_SUCCESS}`:
        if (result.date_range.range) {
          dispatch(dateActions.setDatePreset(result.date_range))
        } else {
          dispatch(
            dateActions.setDateRange(
              result.date_range.startDate,
              result.date_range.endDate,
            ),
          )
        }
        break
      case `user_${actionTypes.FETCH_SUCCESS}`:
        startDate = getStartDate(state.router.location.search)
        endDate = getEndDate(state.router.location.search)
        if (startDate && endDate) {
          dispatch(dateActions.setDateRange(startDate, endDate))
        }
        break
      default:
        break
    }
    return next(action)
  }
