function environmentPath() {
  // @ts-expect-error TS(2339) FIXME: Property '_ENV' does not exist on type 'Window & t... Remove this comment to see the full error message
  return window._ENV === 'development' ? 'local.' : ''
}

function accountPort() {
  // @ts-expect-error TS(2339) FIXME: Property '_ENV' does not exist on type 'Window & t... Remove this comment to see the full error message
  return window._ENV === 'development' ? ':8081' : ''
}

export default {
  toAccount() {
    // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type '(string |... Remove this comment to see the full error message
    window.location = `https://account.${environmentPath()}buffer.com${accountPort()}/analyze`
  },
  toOnboarding() {
    // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type '(string |... Remove this comment to see the full error message
    window.location = `https://account.${environmentPath()}buffer.com${accountPort()}/onboarding/analyze`
  },
  toLanding() {
    // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type '(string |... Remove this comment to see the full error message
    window.location = `https://${environmentPath()}buffer.com/analyze`
  },
  toMigrationHub() {
    // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type '(string |... Remove this comment to see the full error message
    window.location = `https://account.${environmentPath()}buffer.com${accountPort()}/new-plans?cta=analyze-migration-cta`
  },
}

export const channelsRedirectUrl = () =>
  `https://account.${environmentPath()}buffer.com${accountPort()}/channels`
