import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Loader } from '@bufferapp/components'
import { useQuery } from '@apollo/client'
import { AccountContext, GET_ACCOUNT } from '~/account'

import { ChannelsContext } from '../../ChannelsContext'

const Center = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  svg {
    width: 32px !important;
    height: 32px !important;
  }
`

const ProfileLoader = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'storeChannel' implicitly has an '... Remove this comment to see the full error message
  storeChannel,
  // @ts-expect-error TS(7031) FIXME: Binding element 'failedToFetchChannels' implicitly... Remove this comment to see the full error message
  failedToFetchChannels,
  // @ts-expect-error TS(7031) FIXME: Binding element 'fetchingChannels' implicitly has ... Remove this comment to see the full error message
  fetchingChannels,
  // @ts-expect-error TS(7031) FIXME: Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
  children,
  // @ts-expect-error TS(7031) FIXME: Binding element 'loading' implicitly has an 'any' ... Remove this comment to see the full error message
  loading,
}) => {
  const [selectedChannel, setSelectedChannel] = useState(null)
  const {
    data,
    error,
    loading: queuedLoading,
    refetch: refetchAccount,
  } = useQuery(GET_ACCOUNT)

  // @ts-expect-error TS(7031) FIXME: Binding element 'account' implicitly has an 'any' ... Remove this comment to see the full error message
  function getChannels({ account }) {
    return account?.currentOrganization?.channels || []
  }

  useEffect(() => {
    // @ts-expect-error TS(2339) FIXME: Property 'appshell' does not exist on type 'Window... Remove this comment to see the full error message
    const { ORGANIZATION_EVENT_KEY } = window?.appshell?.eventKeys || {}

    window.addEventListener(ORGANIZATION_EVENT_KEY, refetchAccount)

    return function cleanup() {
      window.removeEventListener(ORGANIZATION_EVENT_KEY, refetchAccount)
    }
  }, [])

  useEffect(() => {
    if (!queuedLoading && !!data) {
      // @ts-expect-error TS(7006) FIXME: Parameter 'channel' implicitly has an 'any' type.
      const channels = getChannels(data).filter((channel) => !channel.isLocked)
      const organizations = data?.account?.organizations || []
      const accountChannels = organizations.reduce(
        // @ts-expect-error TS(7006) FIXME: Parameter 'prev' implicitly has an 'any' type.
        (prev, next) => prev.concat(next.channels),
        [],
      )
      storeChannel(channels, accountChannels)
    }
  }, [data, queuedLoading])

  if (error) {
    failedToFetchChannels()
    return null
  }

  // We use the loading provided by the state to make sure the channels have been propagated
  if (loading) {
    fetchingChannels()
    return (
      <Center>
        <Loader />
      </Center>
    )
  }

  if (queuedLoading && !data) {
    return (
      <Center>
        <Loader />
      </Center>
    )
  }

  return (
    <AccountContext.Provider value={{ ...data?.account, loading }}>
      <ChannelsContext.Provider
        value={{
          channels: getChannels(data),
          selectedChannel,
          // @ts-expect-error TS(2322) FIXME: Type 'Dispatch<SetStateAction<null>>' is not assig... Remove this comment to see the full error message
          setSelectedChannel,
        }}
      >
        {children}
      </ChannelsContext.Provider>
    </AccountContext.Provider>
  )
}

ProfileLoader.propTypes = {
  children: PropTypes.node.isRequired,
  storeChannel: PropTypes.func.isRequired,
}

export default ProfileLoader
