import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { Text, nevada } from '@bufferapp/components'
import { presets } from '~/date-picker'

const Date = styled.span`
  color: ${nevada};
`

// @ts-expect-error TS(7006) FIXME: Parameter 'date' implicitly has an 'any' type.
function formatDate(date) {
  return dayjs(date, 'MM/DD/YYYY').format('MMMM D, YYYY')
}

// @ts-expect-error TS(7031) FIXME: Binding element 'updated_at' implicitly has an 'an... Remove this comment to see the full error message
const DateComponent = ({ updated_at, small, date_range }) => {
  let date
  if (date_range) {
    const selectedPreset = presets.find(
      (preset) => preset.range == date_range.range,
    )
    if (date_range.range && selectedPreset) {
      date = selectedPreset.label
    } else {
      date = `${formatDate(date_range.start)} to ${formatDate(date_range.end)}`
    }
  } else {
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    date = presets.find((preset) => preset.range === 7).label
  }

  return (
    <Text size={small ? 'small' : null}>
      <Date>{date}</Date>
    </Text>
  )
}

DateComponent.defaultProps = {
  small: false,
}

DateComponent.propTypes = {
  updated_at: PropTypes.number.isRequired,
  small: PropTypes.bool,
}

export default DateComponent
