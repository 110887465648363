import React from 'react'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { gray, white, grayDarker } from '@bufferapp/ui/style/colors'
import { fontWeightMedium } from '@bufferapp/ui/style/fonts'
import { Calendar, Clock, List, ChartLine } from '@bufferapp/ui/Icon'
import { Text, Button } from '@bufferapp/ui'
import { useSplitEnabled } from '@bufferapp/features'
import CampaignSelector from '~/campaign-selector'
import ExportPicker from '~/export-picker'
import Channels from './components/Channels'
import DisconnectedChannelBanner from './components/DisconnectedChannelBanner'
import UnsupportedChannelBanner from './components/UnsupportedChannelBanner'

dayjs.extend(relativeTime)

const HeaderHolder = styled.header`
  background: ${white};
  border-bottom: 1px solid ${gray};
  padding: 1.375rem 1.5rem 0.5rem;
  border-radius: 3px 3px 0 0;
`

const Row = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > * {
    margin: 0;
  }
`

const Stats = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > * + * {
    margin-left: 1rem;
  }
`

const Stat = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${grayDarker};

  & > svg:nth-child(1) {
    margin-right: 0.5rem;
  }
`

const Metadata = styled(Text)`
  font-weight: ${fontWeightMedium};
`

const Buttons = styled.div`
  display: flex;
  flex-direction: row;

  div:nth-child(2) {
    margin-left: 4px;
  }
`

// @ts-expect-error TS(7006) FIXME: Parameter 'name' implicitly has an 'any' type.
const removeEmoji = (name) =>
  name.replace(
    /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
    '',
  )

// @ts-expect-error TS(7006) FIXME: Parameter 'start' implicitly has an 'any' type.
const isSameMonth = (start, end) => {
  const startMonth = dayjs(start).month()
  const endMonth = dayjs(end).month()
  return startMonth === endMonth
}

// @ts-expect-error TS(7006) FIXME: Parameter 'start' implicitly has an 'any' type.
const isSameYear = (start, end) => {
  const startYear = dayjs(start).year()
  const endYear = dayjs(end).year()
  return startYear === endYear
}

// @ts-expect-error TS(7031) FIXME: Binding element 'campaign' implicitly has an 'any'... Remove this comment to see the full error message
export const CampaignDate = ({ campaign }) => (
  <React.Fragment>
    {campaign.start_date !== campaign.end_date && (
      <Metadata type="p" color={grayDarker}>
        {isSameYear(campaign.start_date, campaign.end_date)
          ? dayjs(campaign.start_date).format('MMM D')
          : dayjs(campaign.start_date).format('MMM D, YYYY')}{' '}
        -{' '}
        {isSameMonth(campaign.start_date, campaign.end_date)
          ? dayjs(campaign.end_date).format('D, YYYY')
          : dayjs(campaign.end_date).format('MMM D, YYYY')}
      </Metadata>
    )}
    {campaign.start_date === campaign.end_date && (
      <Metadata type="p" color={grayDarker}>
        {dayjs(campaign.start_date).format('MMM D, YYYY')}
      </Metadata>
    )}
  </React.Fragment>
)

// @ts-expect-error TS(7006) FIXME: Parameter 'updatedAt' implicitly has an 'any' type... Remove this comment to see the full error message
const getLastUpdated = (updatedAt) => {
  if (updatedAt === 0) {
    return 'time unavailable'
  }

  return dayjs(updatedAt).fromNow()
}

// @ts-expect-error TS(7031) FIXME: Binding element 'campaign' implicitly has an 'any'... Remove this comment to see the full error message
const Header = ({ campaign, profiles }) => {
  const { isEnabled: isCampaignsToTagsEnabled } =
    useSplitEnabled('campaigns-to-tags')
  const tagUrl = isCampaignsToTagsEnabled ? 'tags' : 'campaigns'

  return (
    <React.Fragment>
      {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
      <HeaderHolder channels={campaign.channels} profile={profiles}>
        <Row>
          <CampaignSelector />
          <Buttons>
            {/* @ts-expect-error TS(2740) FIXME: Type '{ label: string; onClick: () => string... Remove this comment to see the full error message */}
            <Button
              label={`Edit ${isCampaignsToTagsEnabled ? 'tag' : 'campaign'}`}
              onClick={() =>
                // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type '(string |... Remove this comment to see the full error message
                (window.location = `https://publish.buffer.com/${tagUrl}/${campaign.id}/scheduled`)
              }
            />
            {campaign && campaign.sent > 0 && (
              <ExportPicker
                // @ts-expect-error TS(2322) FIXME: Type '{ filename: string; }' is not assignable to ... Remove this comment to see the full error message
                filename={`buffer-campaigns-analytics-${removeEmoji(
                  campaign.name,
                )}`}
              />
            )}
          </Buttons>
        </Row>
        <Row>
          <Stats>
            {'channels' in campaign && (
              <Channels channels={campaign.channels} profiles={profiles} />
            )}
            {campaign.start_date && campaign.end_date && (
              <Stat>
                <Calendar />
                <CampaignDate campaign={campaign} />
              </Stat>
            )}
            <Stat>
              <Clock color={grayDarker} />
              <Metadata type="p" color={grayDarker}>
                {campaign.scheduled} Scheduled Posts
              </Metadata>
            </Stat>
            <Stat>
              <List color={grayDarker} />
              <Metadata type="p" color={grayDarker}>
                {campaign.sent} Sent
              </Metadata>
            </Stat>
          </Stats>
          <Stat>
            <ChartLine />
            <Text type="p" color={gray}>
              Last updated {getLastUpdated(campaign.updated_at)}
            </Text>
          </Stat>
        </Row>
      </HeaderHolder>
      <DisconnectedChannelBanner campaign={campaign} profiles={profiles} />
      <UnsupportedChannelBanner campaign={campaign} />
    </React.Fragment>
  )
}

Header.defaultProps = {
  profiles: [],
}

Header.propTypes = {
  campaign: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
    channels: PropTypes.arrayOf(
      PropTypes.shape({
        service_id: PropTypes.string,
        service_type: PropTypes.string,
        service_username: PropTypes.string,
      }),
    ),
  }).isRequired,
  profiles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ),
}

export default Header
