// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import { actionTypes as asyncDataFetchActionTypes } from '@bufferapp/async-data-fetch'
import { actionTypes as profileActionTypes } from '~/profile-selector'
import { dataParser } from './query'

// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import keyWrapper from '@bufferapp/keywrapper'

export const actionTypes = keyWrapper('POSTS_TABLE', {
  FETCH: 'FETCH',
  SAVE: 'SAVE',
  SELECT_TOP_POSTS_METRIC: 'SELECT_TOP_POSTS_METRIC',
  SELECT_TOP_POSTS_COUNT: 'SELECT_TOP_POSTS_COUNT',
  SELECT_TOP_POSTS_ORDER: 'SELECT_TOP_POSTS_ORDER',
  SEARCH: 'SEARCH',
})

export const defaultSortMetrics = {
  facebook: {
    key: 'impressions',
    apiKey: 'impressions',
    label: 'Impressions',
  },
  twitter: {
    key: 'impressions',
    apiKey: 'impressions',
    label: 'Impressions',
  },
  instagram: {
    key: 'likes',
    apiKey: 'likes',
    label: 'Likes',
  },
  linkedin: {
    key: 'impressions',
    apiKey: 'impressions',
    label: 'Impressions',
  },
}

const initialState = {
  posts: [],
  loading: true,
  hasError: false,
  searching: false,
  metrics: [],
  isDescendingSelected: true,
  selectedMetric: {},
  activePostsCount: 5,
  searchTerms: [],
}

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
export default (state = initialState, action) => {
  switch (action.type) {
    case `posts_${asyncDataFetchActionTypes.FETCH_START}`:
      return {
        ...initialState,
        posts: state.posts,
        // @ts-expect-error TS(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
        activePostsCount: parseInt(state.activePostsCount, 10),
        selectedMetric: state.selectedMetric,
        isDescendingSelected: state.isDescendingSelected,
        searchTerms: state.searchTerms,
      }
    case actionTypes.SAVE:
    case `posts_${asyncDataFetchActionTypes.FETCH_SUCCESS}`:
      return {
        ...state,
        loading: false,
        searching: false,
        posts: action.result,
      }
    case actionTypes.SELECT_TOP_POSTS_METRIC:
      return {
        ...state,
        selectedMetric: action.metric,
      }
    case actionTypes.SELECT_TOP_POSTS_COUNT:
      return {
        ...state,
        activePostsCount: parseInt(action.postsCount, 10),
      }
    case profileActionTypes.SELECT_PROFILE:
      return {
        ...initialState,
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        selectedMetric: defaultSortMetrics[action.profile.service],
      }
    case actionTypes.SELECT_TOP_POSTS_ORDER:
      return {
        ...state,
        isDescendingSelected: action.isDescendingSelected,
      }
    case actionTypes.SEARCH:
      return {
        ...state,
        searching: true,
        searchTerms: action.tags,
      }
    case `posts_${asyncDataFetchActionTypes.FETCH_FAIL}`:
      return {
        ...initialState,
        loading: false,
        hasError: true,
      }
    default:
      return state
  }
}

export const actions = {
  fetch: () => ({
    type: actionTypes.FETCH,
  }),
  // @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
  save: (data) => ({
    type: actionTypes.SAVE,
    result: dataParser(data).metrics,
  }),
  // @ts-expect-error TS(7006) FIXME: Parameter 'metric' implicitly has an 'any' type.
  selectMetric(metric) {
    return {
      type: actionTypes.SELECT_TOP_POSTS_METRIC,
      metric,
    }
  },
  // @ts-expect-error TS(7006) FIXME: Parameter 'postsCount' implicitly has an 'any' typ... Remove this comment to see the full error message
  handlePostsCountClick(postsCount) {
    return {
      type: actionTypes.SELECT_TOP_POSTS_COUNT,
      postsCount,
    }
  },
  // @ts-expect-error TS(7006) FIXME: Parameter 'isDescendingSelected' implicitly has an... Remove this comment to see the full error message
  handlePostsSortClick(isDescendingSelected) {
    return {
      type: actionTypes.SELECT_TOP_POSTS_ORDER,
      isDescendingSelected,
    }
  },
  // @ts-expect-error TS(7006) FIXME: Parameter 'tags' implicitly has an 'any' type.
  search(tags) {
    return {
      type: actionTypes.SEARCH,
      tags,
    }
  },
}
