import React from 'react'
import { Avatar } from '@bufferapp/ui'
import styled from 'styled-components'

interface ProfileBadgeProps {
  avatarUrl: string
  service: string
  avatarSize?: string
}

const StyledAvatar = styled(Avatar)`
  margin-right: 16px;
`

const ProfileBadge = ({
  avatarUrl,
  service,
  avatarSize = 'small',
}: ProfileBadgeProps): JSX.Element => {
  return (
    <StyledAvatar
      src={avatarUrl}
      type="social"
      network={service}
      size={avatarSize}
      alt={service}
    />
  )
}

export default ProfileBadge
