// component vs. container https://medium.com/@dan_abramov/smart-and-dumb-components-7ca2f9a7c7d0
import { connect } from 'react-redux'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { push } from 'react-router-redux'
// load the presentational component
import AppSidebar from './components/AppSidebar'

export default connect(
  (state) => ({
    // @ts-expect-error TS(2339) FIXME: Property 'i18n' does not exist on type 'DefaultRoo... Remove this comment to see the full error message
    translations: state.i18n.translations.example, // all package translations
    // @ts-expect-error TS(2339) FIXME: Property 'appSidebar' does not exist on type 'Defa... Remove this comment to see the full error message
    user: state.appSidebar.user,
    // @ts-expect-error TS(2339) FIXME: Property 'environment' does not exist on type 'Def... Remove this comment to see the full error message
    environment: state.environment.environment,
  }),
  (dispatch) => ({
    onUpgradeToProClick: () =>
      dispatch({
        type: 'MODALS__SHOW_UPGRADE_MODAL',
        source: 'app_sidebar',
      }),
    // @ts-expect-error TS(7031) FIXME: Binding element 'menuItemKey' implicitly has an 'a... Remove this comment to see the full error message
    onMenuItemClick: ({ menuItemKey }) => {
      switch (menuItemKey) {
        case 'preferences':
          dispatch(push('/preferences/general'))
          break
        default:
          throw new Error(`Unknown menu item key ${menuItemKey}`)
      }
    },
  }),
)(AppSidebar)

// export reducer, actions and action types
export { default as reducer, actions, actionTypes } from './reducer'
export { default as middleware } from './middleware'
/*
a consumer of a package should be able to use the package in the following way:
import Example, { actions, actionTypes, middleware, reducer } from '@bufferapp/publish-example';
*/
