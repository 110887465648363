// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import { actionTypes as asyncDataFetchActionTypes } from '@bufferapp/async-data-fetch'
import { actionTypes as profileActionTypes } from '~/profile-selector'
import { dataParser } from './query'

// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import keyWrapper from '@bufferapp/keywrapper'

export const actionTypes = keyWrapper('HASHTAGS_TABLE', {
  FETCH: 'FETCH',
  SAVE: 'SAVE',
  SORT_BY: 'SORT_BY',
})

const initialState = {
  hashtags: [],
  labels: [],
  loading: true,
  hasError: false,
  numToShow: 5,
  sortBy: 'primary_metric',
  isDescending: true,
}

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
export default (state = initialState, action) => {
  switch (action.type) {
    case `hashtags_${asyncDataFetchActionTypes.FETCH_START}`:
      return {
        ...initialState,
        // @ts-expect-error TS(2339) FIXME: Property 'activePostsCount' does not exist on type... Remove this comment to see the full error message
        activePostsCount: parseInt(state.activePostsCount, 10),
        // @ts-expect-error TS(2339) FIXME: Property 'selectedMetric' does not exist on type '... Remove this comment to see the full error message
        selectedMetric: state.selectedMetric,
        isDescending: state.isDescending,
      }
    case actionTypes.SAVE:
      return {
        ...initialState,
        loading: false,
        // @ts-expect-error TS(2554) FIXME: Expected 2 arguments, but got 1.
        hashtags: dataParser(action.result).hashtags,
        labels: {
          primary_metric: 'Impressions',
          secondary_metric: 'Engagement Rate',
        },
      }
    case `hashtags_${asyncDataFetchActionTypes.FETCH_SUCCESS}`:
      return {
        ...state,
        loading: false,
        hashtags: action.result.hashtags,
        labels: action.result.labels,
      }
    case profileActionTypes.SELECT_PROFILE:
      return {
        ...initialState,
      }
    case actionTypes.SORT_BY:
      return {
        ...state,
        isDescending: action.isDescending,
        sortBy: action.sortBy,
      }
    case `hashtags_${asyncDataFetchActionTypes.FETCH_FAIL}`:
      return {
        ...initialState,
        loading: false,
        hasError: true,
      }
    default:
      return state
  }
}

export const actions = {
  fetch: () => ({
    type: actionTypes.FETCH,
  }),
  // @ts-expect-error TS(7006) FIXME: Parameter 'result' implicitly has an 'any' type.
  save: (result) => ({
    type: actionTypes.SAVE,
    result,
  }),
  // @ts-expect-error TS(7006) FIXME: Parameter 'metricKey' implicitly has an 'any' type... Remove this comment to see the full error message
  sortBy(metricKey, isDescending) {
    return {
      type: actionTypes.SORT_BY,
      isDescending,
      sortBy: metricKey,
    }
  },
}
