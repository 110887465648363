import React from 'react'
import PropTypes from 'prop-types'
import { Text } from '@bufferapp/components'

import * as styles from './styles'
import Metrics from './Metrics'

// @ts-expect-error TS(7031) FIXME: Binding element 'item' implicitly has an 'any' typ... Remove this comment to see the full error message
const Source = ({ item, number, forReport }) => (
  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
  <styles.ItemContainer forReport={forReport}>
    <styles.HorizontalContainer>
      <span>
        <Text size="large" weight="bold" color="outerSpace">
          {number}
        </Text>
      </span>
      {item.icon}
      <styles.VerticalContainer>
        <Text color="shuttleGray" size="mini">
          Source
        </Text>
        <Text size="large" weight="bold" color="outerSpace">
          {item.source}
        </Text>
      </styles.VerticalContainer>
    </styles.HorizontalContainer>
    <styles.HorizontalContainer>
      {<Metrics metrics={item.metrics} />}
    </styles.HorizontalContainer>
  </styles.ItemContainer>
)

Source.defaultProps = {
  item: {},
  number: null,
}

Source.propTypes = {
  item: PropTypes.shape({}),
  number: PropTypes.number,
}

export default Source
