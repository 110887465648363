import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Text, Link } from '@bufferapp/components'
import AudienceOverview from '~/demographic-overview'
import AudienceGenderAge from '~/demographic-gender-age'
import AudienceCities from '~/demographic-cities'
import AudienceCountries from '~/demographic-countries'
import ContentMask from '~/content-mask'
import { HC_UTM_PARAMS } from '../../../utils/constants'

import { ChartStateNoData as NoData, ChartCard } from '~/shared-components'

const GridContainer = styled.div`
  position: relative;
  padding: 6rem 0.75rem 6.5rem 1rem;
`

const Header = styled.div`
  padding: 0;
  margin: 0;
`

const Description = styled.div`
  padding: 0 3rem;
  margin: 0;
  line-height: 1rem;
`

// @ts-expect-error TS(7006) FIXME: Parameter 'profileId' implicitly has an 'any' type... Remove this comment to see the full error message
function isSupported(profileId, profiles) {
  // @ts-expect-error TS(7006) FIXME: Parameter 'p' implicitly has an 'any' type.
  const profile = profiles.find((p) => p.id === profileId)
  return profile ? profile.service !== 'twitter' : false
}

// @ts-expect-error TS(7031) FIXME: Binding element 'match' implicitly has an 'any' ty... Remove this comment to see the full error message
const AudienceTab = ({ match, profiles }) => (
  <Fragment>
    <ContentMask channel={match.params.channel}>
      {isSupported(match.params.id, profiles) && (
        <div>
          <AudienceOverview />
          <AudienceGenderAge />
          <AudienceCities />
          <AudienceCountries />
        </div>
      )}
    </ContentMask>
    {!isSupported(match.params.id, profiles) && (
      <ChartCard>
        <GridContainer id="js-dom-to-png-audience-summary">
          <NoData>
            <Header>
              <Text weight="medium" color="outerSpace">
                Twitter demographics are currently not available.&nbsp;
                <Link
                  href={`https://support.buffer.com/article/529-analyzing-your-audience?${HC_UTM_PARAMS}`}
                  newTab
                >
                  Why?
                </Link>
              </Text>
            </Header>
            <Description>
              <Text size="small">
                Please choose a Facebook or Instagram account from the dropdown
              </Text>
            </Description>
          </NoData>
        </GridContainer>
      </ChartCard>
    )}
  </Fragment>
)

AudienceTab.propTypes = {
  profiles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
}

export default connect((state) => ({
  // @ts-expect-error TS(2339) FIXME: Property 'profiles' does not exist on type 'Defaul... Remove this comment to see the full error message
  profiles: state.profiles ? state.profiles.socialProfiles : [],
}))(AudienceTab)
