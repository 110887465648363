import React from 'react'
import PropTypes from 'prop-types'

import { geyser, AvatarIcon } from '@bufferapp/components'

// @ts-expect-error TS(7031) FIXME: Binding element 'color' implicitly has an 'any' ty... Remove this comment to see the full error message
const UserAvatar = ({ color, size }) => {
  const style: React.CSSProperties = {
    height: size.height,
    width: size.width,
    position: 'relative',
  }
  const avatarStyle: React.CSSProperties = {
    position: 'absolute',
    top: 0,
    left: 0,
  }
  return (
    <div style={style}>
      <div style={avatarStyle}>
        <AvatarIcon color={color} size={size} />
      </div>
    </div>
  )
}

UserAvatar.propTypes = {
  color: PropTypes.string,
  size: PropTypes.shape({
    width: PropTypes.string.isRequired,
    height: PropTypes.string.isRequired,
  }),
}

UserAvatar.defaultProps = {
  color: geyser,
  size: {
    width: '27px',
    height: '27px',
  },
}

export default UserAvatar
