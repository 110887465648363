// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import { actionTypes as asyncDataFetchActionTypes } from '@bufferapp/async-data-fetch'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import keyWrapper from '@bufferapp/keywrapper'

export const actionTypes = keyWrapper('CAMPAIGNS', {
  FETCH: 'FETCH',
  FETCH_CAMPAIGNS: 'FETCH_CAMPAIGNS',
  SET_TAGS_STATUS: 'TAGS_STATUS',
})

const initialState = {
  campaigns: [],
  hasError: false,
  loading: true,
  renderCrossSell: false,
  selectedCampaign: null,
  tagsEnabled: false,
}

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_TAGS_STATUS:
      return {
        ...state,
        tagsEnabled: action.tagsEnabled,
      }
    case `user_${asyncDataFetchActionTypes.FETCH_FAIL}`:
      return {
        ...state,
        renderCrossSell: true,
      }

    case `campaigns_${asyncDataFetchActionTypes.FETCH_START}`:
      return {
        ...state,
        campaigns: [],
      }

    case `campaigns_${asyncDataFetchActionTypes.FETCH_FAIL}`:
      return {
        ...state,
        loading: false,
        hasError: true,
      }

    case `campaigns_${asyncDataFetchActionTypes.FETCH_SUCCESS}`:
      return {
        ...state,
        loading: false,
        campaigns: action.result,
      }

    default:
      return state
  }
}

export const actions = {
  fetch: () => ({
    type: actionTypes.FETCH,
  }),
  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  fetchCampaigns: (id) => ({
    type: actionTypes.FETCH_CAMPAIGNS,
    id,
  }),
  // @ts-expect-error TS(7006) FIXME: Parameter 'tagsEnabled' implicitly has an 'any' ty... Remove this comment to see the full error message
  setTagsStatus: (tagsEnabled) => ({
    type: actionTypes.SET_TAGS_STATUS,
    tagsEnabled,
  }),
}
