// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import { actionTypes as asyncDataFetchActionTypes } from '@bufferapp/async-data-fetch'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import keyWrapper from '@bufferapp/keywrapper'

export const actionTypes = keyWrapper('BOOSTED_POSTS_NOTICE', {
  FETCH: 'FETCH',
})

const initialState = {
  loading: true,
  hasError: false,
  adsEnabled: null,
}

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
export default (state = initialState, action) => {
  switch (action.type) {
    case `has_ads_account_${asyncDataFetchActionTypes.FETCH_START}`:
      return initialState
    case `has_ads_account_${asyncDataFetchActionTypes.FETCH_FAIL}`:
      return {
        ...initialState,
        loading: false,
        hasError: true,
      }
    case `has_ads_account_${asyncDataFetchActionTypes.FETCH_SUCCESS}`:
      return {
        loading: false,
        hasError: false,
        adsEnabled: action.result,
      }
    default:
      return state
  }
}

export const actions = {
  fetch: () => ({
    type: actionTypes.FETCH,
  }),
}
