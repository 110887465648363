import styled from 'styled-components'
import { outerSpace, shuttleGray } from '@bufferapp/components'

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  padding: ${(props) =>
      // @ts-expect-error TS(2339) FIXME: Property 'big' does not exist on type 'ThemedStyle... Remove this comment to see the full error message
      props.big ? '20px' : '12px'}
    0
    ${(props) =>
      // @ts-expect-error TS(2339) FIXME: Property 'big' does not exist on type 'ThemedStyle... Remove this comment to see the full error message
      props.big ? '20px' : '12px'}
    16px;
  justify-content: space-between;
  background: #ffffff;
  border-bottom: 1px solid #dbdbdb;
  box-sizing: border-box;

  :first-child {
    margin-top: 0;
    border-top: 1px solid #dbdbdb;
    box-shadow: ${(props) =>
      // @ts-expect-error TS(2339) FIXME: Property 'forReport' does not exist on type 'Theme... Remove this comment to see the full error message
      props.forReport ? 'none' : '0px 2px 2px rgba(0, 0, 0, 0.1) inset'};
  }

  :last-child {
    margin-bottom: 0;
    border-bottom: ${(props) =>
      // @ts-expect-error TS(2339) FIXME: Property 'forReport' does not exist on type 'Theme... Remove this comment to see the full error message
      props.forReport ? 'none' : '1px solid #dbdbdb'};
  }

  li {
    :last-child {
      padding-right: 0;
    }
  }
`

export const HorizontalContainer = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
`

export const MetricsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

export const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  min-width: 0;
  height: ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'height' does not exist on type 'ThemedSt... Remove this comment to see the full error message
    props.height || 'auto'};
`

export const ProductImage = styled.div`
  flex-shrink: 0;
  width: 178px;
  height: 178px;
  margin: 0 18px;
  border-radius: 4px;
  background: transparent;
  background-image: url(${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'thumbnail' does not exist on type 'Theme... Remove this comment to see the full error message
    props.thumbnail});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`

export const ProductMeta = styled.p`
  font-family: Roboto, sans-serif;
  line-height: 18px;
  color: ${shuttleGray};
  font-size: 14px;
  margin: 6px 0 12px;
  flex-grow: 1;

  b {
    color: ${outerSpace};
  }
`

export const ProductMetaLabel = styled.div`
  margin: 0 0 0.25rem;
`

export const ProductMetaValue = styled.div`
  font-weight: bold;
  color: ${outerSpace};
`

export const ProductReferralsStyled = styled.div`
  margin: 0;
  padding-top: 18px;
  flex-grow: 1;
`

export const ProductReferralsContainer = styled.ol`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  list-style: none;
  counter-reset: referrals-counter;
  padding: 4px 0 0 0;
  margin: 0px;
  flex-grow: 1;

  li {
    counter-increment: referrals-counter;
    display: flex;
    margin: 6px 0;

    span {
      white-space: nowrap;
    }
  }

  li::before {
    content: counter(referrals-counter);
    color: ${outerSpace};
    font-family: Roboto, sans-serif;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.25px;
  }
`

export const ProductReferralNameHolder = styled.span`
  display: flex;
  width: 65.7%;
  & > span:last-child {
    display: inline-flex;
    width: 100%;
    align-items: center;
  }

  & > span:last-child::after {
    content: '';
    width: 100%;
    display: inline-block;
    margin: 0 10px;
    height: 0px;
    border-bottom: 1px #dbdbdb dotted;
    float: right;
  }
`

export const ProductName = styled.p`
  margin: 0;
  flex-grow: 1;
  min-width: 0; /* or some value */

  span:first-child {
    display: inline-block;
    margin: 0 0 4px;
  }
`

export const ProductTruncate = styled.span`
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
