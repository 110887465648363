import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import styled, { css } from 'styled-components'

import {
  Button,
  Text,
  geyser,
  offWhite,
  ArrowLeftIcon,
  ArrowRightIcon,
} from '@bufferapp/components'

import Month from './Month'

const DayHeaderListItem = styled.li`
  display: inline-block;
  text-decoration: none;
  width: 1.7rem;
  height: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
  border-bottom: solid 1px ${geyser};
`

const DayHeaderList = styled.ul`
  padding: 0;
  margin: 1rem;
  display: flex;
  justify-content: center;
`

const Header = styled.header`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 2rem;
  border-bottom: solid 1px ${geyser};
  text-align: center;
  background: ${offWhite};
`

const ArrowLeft = styled.i`
  cursor: pointer;
  margin-left: 1rem;
`

const ArrowRight = styled.i`
  cursor: pointer;
  margin-right: 1rem;
`

const Container = styled.aside`
  display: none;
  margin-top: 1rem;
  border: solid 1px ${geyser};
  border-radius: 3px;

  ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'isOpen' does not exist on type 'ThemedSt... Remove this comment to see the full error message
    props.isOpen &&
    css`
      display: block;
    `}
`

const MonthHeader = () => {
  const days = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ].map((day) => (
    <DayHeaderListItem key={day}>
      <Text size="small">{day.charAt(0)}</Text>
    </DayHeaderListItem>
  ))

  return <DayHeaderList>{days}</DayHeaderList>
}

class Calendar extends Component {
  // @ts-expect-error TS(7006) FIXME: Parameter 'unixTimestamp' implicitly has an 'any' ... Remove this comment to see the full error message
  getHeader(unixTimestamp) {
    const currentMonth = moment.unix(unixTimestamp).format('MMMM YYYY')

    return (
      <Header>
        <Button noStyle onClick={() => this.previousMonth(unixTimestamp)}>
          <ArrowLeft>
            <ArrowLeftIcon />
          </ArrowLeft>
        </Button>
        <Text size="small">{currentMonth}</Text>
        <Button noStyle onClick={() => this.nextMonth(unixTimestamp)}>
          <ArrowRight>
            <ArrowRightIcon />
          </ArrowRight>
        </Button>
      </Header>
    )
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'unixTimestamp' implicitly has an 'any' ... Remove this comment to see the full error message
  previousMonth(unixTimestamp) {
    const m = moment.unix(unixTimestamp)
    m.subtract(1, 'months')
    // @ts-expect-error TS(2339) FIXME: Property 'selectMonth' does not exist on type 'Rea... Remove this comment to see the full error message
    this.props.selectMonth(m.unix())
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'unixTimestamp' implicitly has an 'any' ... Remove this comment to see the full error message
  nextMonth(unixTimestamp) {
    const m = moment.unix(unixTimestamp)
    m.add(1, 'months')
    // @ts-expect-error TS(2339) FIXME: Property 'selectMonth' does not exist on type 'Rea... Remove this comment to see the full error message
    this.props.selectMonth(m.unix())
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'timestamp' implicitly has an 'any' type... Remove this comment to see the full error message
  handleDayClick(timestamp) {
    const {
      // @ts-expect-error TS(2339) FIXME: Property 'startDate' does not exist on type 'Reado... Remove this comment to see the full error message
      startDate,
      // @ts-expect-error TS(2339) FIXME: Property 'focusStartDate' does not exist on type '... Remove this comment to see the full error message
      focusStartDate,
      // @ts-expect-error TS(2339) FIXME: Property 'focusEndDate' does not exist on type 'Re... Remove this comment to see the full error message
      focusEndDate,
      // Actions
      // @ts-expect-error TS(2339) FIXME: Property 'selectStartDate' does not exist on type ... Remove this comment to see the full error message
      selectStartDate,
      // @ts-expect-error TS(2339) FIXME: Property 'selectEndDate' does not exist on type 'R... Remove this comment to see the full error message
      selectEndDate,
    } = this.props

    const m = moment.unix(timestamp)
    const mStartDate = moment.unix(startDate)

    if (focusStartDate) {
      selectStartDate(timestamp)
    } else if (focusEndDate && (startDate === -1 || m.isAfter(mStartDate))) {
      selectEndDate(timestamp)
    }
  }

  render() {
    const {
      // @ts-expect-error TS(2339) FIXME: Property 'startDate' does not exist on type 'Reado... Remove this comment to see the full error message
      startDate,
      // @ts-expect-error TS(2339) FIXME: Property 'endDate' does not exist on type 'Readonl... Remove this comment to see the full error message
      endDate,
      // @ts-expect-error TS(2339) FIXME: Property 'maxStartDate' does not exist on type 'Re... Remove this comment to see the full error message
      maxStartDate,
      // @ts-expect-error TS(2339) FIXME: Property 'maxEndDate' does not exist on type 'Read... Remove this comment to see the full error message
      maxEndDate,
      // @ts-expect-error TS(2339) FIXME: Property 'isOpen' does not exist on type 'Readonly... Remove this comment to see the full error message
      isOpen,
      // @ts-expect-error TS(2339) FIXME: Property 'currentMonth' does not exist on type 'Re... Remove this comment to see the full error message
      currentMonth,
    } = this.props

    const header = this.getHeader(currentMonth)

    return (
      // @ts-expect-error TS(2769) FIXME: No overload matches this call.
      <Container isOpen={isOpen}>
        {header}
        <MonthHeader />
        <Month
          // @ts-expect-error TS(2769) FIXME: No overload matches this call.
          handleDayClick={(timestamp) => this.handleDayClick(timestamp)}
          currentMonth={currentMonth}
          startDate={startDate}
          endDate={endDate}
          maxStartDate={maxStartDate}
          maxEndDate={maxEndDate}
        />
      </Container>
    )
  }
}

// @ts-expect-error TS(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
Calendar.defaultProps = {
  startDate: null,
  endDate: null,
}

// @ts-expect-error TS(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
Calendar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  startDate: PropTypes.number,
  endDate: PropTypes.number,
  focusStartDate: PropTypes.bool.isRequired,
  focusEndDate: PropTypes.bool.isRequired,
  currentMonth: PropTypes.number.isRequired,
  maxStartDate: PropTypes.number,
  maxEndDate: PropTypes.number,

  // Actions
  selectStartDate: PropTypes.func.isRequired,
  selectEndDate: PropTypes.func.isRequired,
  selectMonth: PropTypes.func.isRequired,
}

export default Calendar
