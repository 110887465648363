import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import RemovableReport from './RemovableReport'
import SelectableReport from './SelectableReport'
import { outerSpaceLight, curiousBlue } from '@bufferapp/components'

const ReportListItem = styled.li`
  transition: all 0.2s ease-out;
  display: flex;
  background: #ffffff;
  border: 1px solid #ced7df;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-bottom: 0.5rem;
  min-height: 3rem;

  &:hover {
    cursor: pointer;
    border: 1px solid ${curiousBlue};
    box-shadow: 0 1px 2px ${outerSpaceLight};
  }
  &:active {
    border: 1px solid #0e5c98;
    box-shadow: 0 1px 4px ${outerSpaceLight};
  }
`

class Report extends Component {
  static propTypes = {
    removeReport: PropTypes.func,
  }

  static defaultProps = {
    removeReport: null,
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
  constructor(props) {
    super(props)
    this.addHover = this.addHover.bind(this)
    this.removeHover = this.removeHover.bind(this)
    this.state = {
      hovered: false,
    }
  }

  addHover() {
    this.setState({
      hovered: true,
    })
  }

  removeHover() {
    this.setState({
      hovered: false,
    })
  }

  render() {
    return (
      <ReportListItem
        onMouseEnter={this.addHover}
        onMouseLeave={this.removeHover}
      >
        {/* @ts-expect-error TS(2339) FIXME: Property 'removeReport' does not exist on type 'Re... Remove this comment to see the full error message */}
        {!this.props.removeReport && <SelectableReport {...this.props} />}
        {/* @ts-expect-error TS(2339) FIXME: Property 'removeReport' does not exist on type 'Re... Remove this comment to see the full error message */}
        {this.props.removeReport && (
          // @ts-expect-error TS(2739) FIXME: Type '{ children?: ReactNode; showButtons: any; }'... Remove this comment to see the full error message
          <RemovableReport showButtons={this.state.hovered} {...this.props} />
        )}
      </ReportListItem>
    )
  }
}

export default Report
