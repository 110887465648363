import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  ChartStateNoData as NoData,
  ChartStateLoading as Loading,
  ChartCard,
  ChartHeader,
} from '~/shared-components'
import { geyser } from '@bufferapp/components'
import AddReport from '~/add-report'
import Title from '../Title'
import Chart from '../Chart'
import Select from '@bufferapp/ui/Select'
import { renderButton } from '~/shared-components/utils'

const HeadersActions = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;

  div[role='button'] {
    width: 150px;
  }
`

const HeaderSpacer = styled.div`
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  width: 1px;
  background: ${geyser};
  height: 30px;
`

const GridContainer = styled.div`
  position: relative;
  padding: 0;
`

// @ts-expect-error TS(7006) FIXME: Parameter 'metrics' implicitly has an 'any' type.
function getSelectedMetricsGroup(metrics, selectedGroup) {
  // @ts-expect-error TS(7006) FIXME: Parameter 'group' implicitly has an 'any' type.
  return metrics.find((group) => group.key === selectedGroup)
}

const AudienceCountriesChart = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'metrics' implicitly has an 'any' ... Remove this comment to see the full error message
  metrics,
  // @ts-expect-error TS(7031) FIXME: Binding element 'loading' implicitly has an 'any' ... Remove this comment to see the full error message
  loading,
  // @ts-expect-error TS(7031) FIXME: Binding element 'selectedGroup' implicitly has an ... Remove this comment to see the full error message
  selectedGroup,
  // @ts-expect-error TS(7031) FIXME: Binding element 'selectMetricsGroup' implicitly ha... Remove this comment to see the full error message
  selectMetricsGroup,
}) => {
  let content = null
  const selectedMetrics = getSelectedMetricsGroup(metrics, selectedGroup)

  console.log('metrics from countries chart', {
    metrics,
    selectedGroup,
    selectMetricsGroup,
    selectedMetrics,
  })

  if (loading) {
    // @ts-expect-error TS(2322) FIXME: Type '{ active: true; noBorder: true; large: true;... Remove this comment to see the full error message
    content = <Loading active noBorder large />
  } else if (
    !selectedMetrics ||
    // @ts-expect-error TS(7006) FIXME: Parameter 'metric' implicitly has an 'any' type.
    selectedMetrics.metrics.every((metric) => metric.values.length === 0)
  ) {
    content = <NoData chartName="demographic-countries" />
  } else {
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    content = <Chart metrics={metrics} selectedGroup={selectedGroup} />
  }

  return (
    <ChartCard>
      <ChartHeader>
        <Title />
        <HeadersActions>
          {metrics.length > 1 && (
            <Select
              // @ts-expect-error TS(7006) FIXME: Parameter 'selectedItem' implicitly has an 'any' t... Remove this comment to see the full error message
              onSelectClick={(selectedItem) =>
                selectedItem.onItemClick(selectedItem.label)
              }
              // @ts-expect-error TS(7006) FIXME: Parameter 'onButtonClick' implicitly has an 'any' ... Remove this comment to see the full error message
              customButton={(onButtonClick) =>
                renderButton(
                  onButtonClick,
                  getSelectedMetricsGroup(metrics, selectedGroup).label,
                  'small',
                )
              }
              // @ts-expect-error TS(7006) FIXME: Parameter 'metric' implicitly has an 'any' type.
              items={metrics.map((metric) => ({
                ...metric,
                onItemClick: selectMetricsGroup,
              }))}
              hideSearch
              keyMap={{
                id: 'key',
                title: 'label',
              }}
              size="small"
              xPosition="right"
              fullWidth
            />
          )}
          {metrics.length > 1 && <HeaderSpacer />}
          {metrics.length > 0 && (
            <AddReport
              chart="demographic-countries"
              state={{
                selectedGroup,
              }}
            />
          )}
        </HeadersActions>
      </ChartHeader>
      <GridContainer id="js-dom-to-png-audience-countries">
        {content}
      </GridContainer>
    </ChartCard>
  )
}

AudienceCountriesChart.defaultProps = {
  loading: false,
  selectMetricsGroup: null,
  selectedGroup: '',
}

AudienceCountriesChart.propTypes = {
  loading: PropTypes.bool,
  metrics: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectMetricsGroup: PropTypes.func,
  selectedGroup: PropTypes.string,
}

export default AudienceCountriesChart
