import React, { useEffect } from 'react'
import dayjs from 'dayjs'
import { useQuery } from '@apollo/client'
import {
  EmptyState,
  ChartStateLoading as Loading,
  ChartCard,
  ChartTitle,
  ChartHeader,
  Container,
} from '~/shared-components'

// @ts-expect-error TS(7031) FIXME: Binding element 'graphQlProps' implicitly has an '... Remove this comment to see the full error message
const GraphQLWrapper = ({ graphQlProps, ...props }) => {
  const {
    // @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
    dataParser = (data) => ({
      metrics: data,
    }),
    query,
    save = () => {},
    variables,
    title,
  } = graphQlProps

  const fetchPolicy = process.env.NODE_ENV === 'test' ? 'no-cache' : undefined
  const { data, error, loading } = useQuery(query, {
    fetchPolicy,
    variables,
  })

  useEffect(() => {
    if (data) {
      save(data)
    }
  }, [data])

  if (error) {
    // TODO push this into bugsnag
    console.log(error)
    return (
      <ChartCard>
        <EmptyState
          header="There was a problem loading the data"
          description="If this persists, please contact us"
        />
      </ChartCard>
    )
  } else if (data && graphQlProps.content) {
    props = {
      ...props,
      ...dataParser(data),
      loading,
    }

    return (
      <React.Fragment>{<graphQlProps.content {...props} />}</React.Fragment>
    )
  }

  if (props.forReport) {
    // @ts-expect-error TS(2322) FIXME: Type '{ active: true; noBorder: true; }' is not as... Remove this comment to see the full error message
    return <Loading active noBorder />
  }

  return (
    <ChartCard>
      {title && (
        <ChartHeader>
          <ChartTitle>{title}</ChartTitle>
        </ChartHeader>
      )}
      <Container>
        {/* @ts-expect-error TS(2322) FIXME: Type '{ active: true; noBorder: true; }' is not as... Remove this comment to see the full error message */}
        <Loading active noBorder />
      </Container>
    </ChartCard>
  )
}

// @ts-expect-error TS(7006) FIXME: Parameter 'profile' implicitly has an 'any' type.
export function formatQueryVariables(profile, variables) {
  return {
    serviceId: profile.serviceId,
    serviceType: profile.service,
    ...variables,
    endDate: dayjs(variables.endDate, 'MM-DD-YYYY').format('YYYY-MM-DD'),
    startDate: dayjs(variables.startDate, 'MM-DD-YYYY').format('YYYY-MM-DD'),
  }
}

export default GraphQLWrapper
