import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Text from '@bufferapp/ui/Text'
import Tooltip from '@bufferapp/ui/Tooltip'
import { TimelineTooltip } from './components/TimelineTooltip/'

const Wrapper = styled.div`
  display: flex;
  align-content: center;
  position: relative;
  margin-top: 16px;
`

const TimelineWrapper = styled.div`
  display: flex;
  width: 100%;
  position: relative;

  label {
    flex: 0 1 auto;
    margin-left: 10px;
  }
`

const Timeline = styled.div`
  height: 0px;
  width: ${(props) =>
    `${
      // @ts-expect-error TS(2339) FIXME: Property 'plotWidth' does not exist on type 'Theme... Remove this comment to see the full error message
      props.plotWidth
    }px`};
  border-bottom: 2px dashed #ccdae5;
  position: absolute;
  left: ${(props) =>
    `${
      // @ts-expect-error TS(2339) FIXME: Property 'plotX' does not exist on type 'ThemedSty... Remove this comment to see the full error message
      props.plotX
    }px`};
  top: 9px;
`

const Post = styled.button`
  width: 19px;
  height: 19px;
  border-radius: 50%;
  border: 2.5px solid #607c93;
  padding: 0;
  background-color: white;
  position: relative;
  transition: transform 125ms ease-out, border-color 125ms linear;

  &:hover {
    cursor: pointer;
    height: 22px;
    width: 22px;
    transform: scale(1.1);
    border-color: #4e6578;
    top: -1px;
    left: -1px;
  }
`

const List = styled.ol`
  margin: 0;
  padding: 0;
  display: flex;
  position: absolute;
  left: ${(props) =>
    `${
      // @ts-expect-error TS(2339) FIXME: Property 'plotX' does not exist on type 'ThemedSty... Remove this comment to see the full error message
      props.plotX
    }px`};
  width: ${(props) =>
    `${
      // @ts-expect-error TS(2339) FIXME: Property 'plotWidth' does not exist on type 'Theme... Remove this comment to see the full error message
      props.plotWidth
    }px`};
`

// @ts-expect-error TS(7031) FIXME: Binding element 'numberOfDays' implicitly has an '... Remove this comment to see the full error message
function getDaysWidth({ numberOfDays, plotWidth, selectedMetric }) {
  return Math.round(
    plotWidth / (numberOfDays - (isAreaMetric({ selectedMetric }) ? 1 : 0)),
  )
}

// @ts-expect-error TS(7031) FIXME: Binding element 'selectedMetric' implicitly has an... Remove this comment to see the full error message
function isAreaMetric({ selectedMetric }) {
  return selectedMetric === 'Total Followers'
}

const Item = styled.li`
  list-style: none;
  height: 20px;
  width: 20px;
  position: absolute;
  left: calc(
    ${(props) =>
        getDaysWidth(
          // @ts-expect-error TS(2345) FIXME: Argument of type 'ThemedStyledProps<Omit<DetailedH... Remove this comment to see the full error message
          props,
          // @ts-expect-error TS(2339) FIXME: Property 'position' does not exist on type 'Themed... Remove this comment to see the full error message
        ) * props.position}px - 10px
  );
  margin-left: ${(props) =>
    // @ts-expect-error TS(2345) FIXME: Argument of type 'ThemedStyledProps<Omit<DetailedH... Remove this comment to see the full error message
    isAreaMetric(props) ? 0 : getDaysWidth(props) / 2}px;

  :hover {
    cursor: pointer;
  }
`

const Highlight = styled.span`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ::before {
    content: '';
    display: block;
    height: 10px;
    width: 10px;
    opacity: 0;
    border-radius: 50%;
    background: #4e6578;
    transition: opacity 125ms ease-out;
  }

  ${Post}:hover & {
    ::before {
      opacity: 1;
    }
  }
`

// @ts-expect-error TS(7006) FIXME: Parameter 'posts' implicitly has an 'any' type.
function dayHasPosts(posts) {
  const numberOfPosts = posts
    // @ts-expect-error TS(7006) FIXME: Parameter 'channel' implicitly has an 'any' type.
    .map((channel) => channel.details.length)
    // @ts-expect-error TS(7006) FIXME: Parameter 'accumulator' implicitly has an 'any' ty... Remove this comment to see the full error message
    .reduce((accumulator, currentValue) => accumulator + currentValue)

  return numberOfPosts > 0
}

function getChartInfo() {
  const campaignPostsMetrics = document.getElementById(
    'js-dom-to-png-campaign-posts-metrics',
  )
  if (campaignPostsMetrics) {
    const charts = campaignPostsMetrics.getElementsByClassName(
      'highcharts-plot-border',
    )
    if (charts.length) {
      // @ts-expect-error TS(2339) FIXME: Property 'getBBox' does not exist on type 'Element... Remove this comment to see the full error message
      const chart = charts[0].getBBox()
      return {
        plotX: chart.x,
        plotWidth: chart.width,
      }
    }
  }

  return null
}

export default class PostsTimeline extends React.PureComponent {
  // @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
  constructor(props) {
    super(props)
    this.state = { refChartExist: false }
  }

  componentDidMount() {
    // @ts-expect-error TS(2339) FIXME: Property 'interval' does not exist on type 'PostsT... Remove this comment to see the full error message
    this.interval = setInterval(() => {
      const chart = getChartInfo()
      if (chart) {
        this.setState({ refChartExist: true })
        // @ts-expect-error TS(2339) FIXME: Property 'interval' does not exist on type 'PostsT... Remove this comment to see the full error message
        clearInterval(this.interval)
      }
    }, 50)
  }

  componentWillUnmount() {
    // @ts-expect-error TS(2339) FIXME: Property 'interval' does not exist on type 'PostsT... Remove this comment to see the full error message
    clearInterval(this.interval)
  }

  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'postInfo' does not exist on type 'Readon... Remove this comment to see the full error message
    const { postInfo, tooltipAction, selectedMetric } = this.props
    // @ts-expect-error TS(2339) FIXME: Property 'refChartExist' does not exist on type 'R... Remove this comment to see the full error message
    const { refChartExist } = this.state
    const chart = getChartInfo()

    return (
      <React.Fragment>
        {refChartExist && (
          <Wrapper>
            <TimelineWrapper {...chart}>
              <Text htmlFor="timeline" type="label">
                Posts
              </Text>
              <Timeline {...chart} id="timeline" />
            </TimelineWrapper>
            <List {...chart}>
              {/* @ts-expect-error TS(7006) FIXME: Parameter 'daily' implicitly has an 'any' type. */}
              {postInfo.map((daily, index) => {
                const dayAsNumber = parseInt(daily.day, 10)
                if (dayHasPosts(daily.posts)) {
                  return (
                    <Item
                      key={index}
                      {...chart}
                      // @ts-expect-error TS(2769) FIXME: No overload matches this call.
                      selectedMetric={selectedMetric}
                      position={index}
                      numberOfDays={postInfo.length}
                    >
                      <Tooltip
                        // @ts-expect-error TS(2322) FIXME: Type '{ children: Element;... Remove this comment to see the full error message
                        customLabel={
                          <TimelineTooltip
                            posts={daily.posts}
                            day={dayAsNumber}
                          />
                        }
                        position="top"
                        opacity={0.9}
                      >
                        <Post onClick={() => tooltipAction(daily.day)}>
                          <Highlight />
                        </Post>
                      </Tooltip>
                    </Item>
                  )
                }
              })}
            </List>
          </Wrapper>
        )}
      </React.Fragment>
    )
  }
}

// @ts-expect-error TS(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
PostsTimeline.propTypes = {
  postInfo: PropTypes.array,
  tooltipAction: PropTypes.func,
  selectedMetric: PropTypes.string,
}
