import {
  actions as asyncDataFetchActions,
  // @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
} from '@bufferapp/async-data-fetch'
import { actionTypes } from './reducer'

// @ts-expect-error TS(7006) FIXME: Parameter 'store' implicitly has an 'any' type.
export default (store) => (next) => (action) => {
  // eslint-disable-line no-unused-vars
  switch (action.type) {
    case actionTypes.FETCH_CAMPAIGNS:
      store.dispatch(
        asyncDataFetchActions.fetch({
          name: 'campaigns',
          args: {
            organization_id: action.id,
          },
        }),
      )
      break
    case 'ORGANIZATION_CHANGED':
      store.dispatch(
        asyncDataFetchActions.fetch({
          name: 'campaigns',
          args: {
            organization_id: action.organizationId,
          },
        }),
      )
      break
    case actionTypes.FETCH:
      store.dispatch(
        asyncDataFetchActions.fetch({
          name: 'getCampaign',
          args: {
            id: store.getState().campaignSelector.selectedCampaign.id,
          },
        }),
      )
      break
    default:
      break
  }

  return next(action)
}
