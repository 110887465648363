import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'
import { Button } from '@bufferapp/components'

import Name from '../Name'
import Date from '../Date'
import ReportText from '../ReportText'
import RemoveButton from '../RemoveButton'

const ButtonContainer = styled.span`
  position: absolute;
  right: 1rem;
  display: flex;
`

const RemovableReport = ({
  // @ts-expect-error TS(7031) FIXME: Binding element '_id' implicitly has an 'any' type... Remove this comment to see the full error message
  _id,
  // @ts-expect-error TS(7031) FIXME: Binding element 'updated_at' implicitly has an 'an... Remove this comment to see the full error message
  updated_at,
  // @ts-expect-error TS(7031) FIXME: Binding element 'name' implicitly has an 'any' typ... Remove this comment to see the full error message
  name,
  // @ts-expect-error TS(7031) FIXME: Binding element 'small' implicitly has an 'any' ty... Remove this comment to see the full error message
  small,
  // @ts-expect-error TS(7031) FIXME: Binding element 'date_range' implicitly has an 'an... Remove this comment to see the full error message
  date_range,
  // @ts-expect-error TS(7031) FIXME: Binding element 'removeReport' implicitly has an '... Remove this comment to see the full error message
  removeReport,
  // @ts-expect-error TS(7031) FIXME: Binding element 'selectReport' implicitly has an '... Remove this comment to see the full error message
  selectReport,
  // @ts-expect-error TS(7031) FIXME: Binding element 'showButtons' implicitly has an 'a... Remove this comment to see the full error message
  showButtons,
}) => (
  <Button noStyle fillContainer onClick={() => selectReport(_id)}>
    {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
    <ReportText small={small}>
      <Name name={name} small={small} />
      {!showButtons && (
        <Date updated_at={updated_at} date_range={date_range} small={small} />
      )}
      {showButtons && (
        <ButtonContainer>
          <RemoveButton _id={_id} removeReport={removeReport} />
        </ButtonContainer>
      )}
    </ReportText>
  </Button>
)

RemovableReport.propTypes = {
  _id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  updated_at: PropTypes.number.isRequired,
  selectReport: PropTypes.func.isRequired,
  removeReport: PropTypes.func,
  small: PropTypes.bool,
  showButtons: PropTypes.bool,
}

RemovableReport.defaultProps = {
  small: false,
  removeReport: null,
  showButtons: false,
}

export default RemovableReport
