// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import { actions } from '@bufferapp/async-data-fetch'
import { actionTypes as profileActionTypes } from '~/profile-selector'
import { actionTypes as dateActionTypes } from '~/date-picker'

const supportedServices = ['facebook', 'instagram']
// @ts-expect-error TS(7006) FIXME: Parameter 'service' implicitly has an 'any' type.
const isSupportedService = (service) =>
  supportedServices.some((supportedService) => service === supportedService)

// @ts-expect-error TS(7006) FIXME: Parameter 'store' implicitly has an 'any' type.
export default (store) => (next) => (action) => {
  // eslint-disable-line no-unused-vars
  const { dispatch, getState } = store
  switch (action.type) {
    case profileActionTypes.SELECT_PROFILE:
      const service = action.profile.service
      isSupportedService(service) &&
        dispatch(
          actions.fetch({
            name: 'demographic',
            args: {
              profileId: action.profile.id,
              profileService: service,
              startDate: getState().date.startDate,
              endDate: getState().date.endDate,
            },
          }),
        )
      break
    case dateActionTypes.SET_DATE_RANGE:
      if (getState().profiles.selectedProfileId) {
        const service = getState().profiles.selectedProfileService
        isSupportedService(service) &&
          dispatch(
            actions.fetch({
              name: 'demographic',
              args: {
                profileId: getState().profiles.selectedProfileId,
                profileService: service,
                startDate: action.startDate,
                endDate: action.endDate,
              },
            }),
          )
      }
      break
    case profileActionTypes.REFRESH_PROFILES:
      if (getState().profiles.selectedProfile) {
        const service = getState().profiles.selectedProfile.service
        isSupportedService(service) &&
          dispatch(
            actions.fetch({
              name: 'demographic',
              args: {
                profileId: getState().profiles.selectedProfile.id,
                profileService: service,
                startDate: getState().date.startDate,
                endDate: getState().date.endDate,
              },
            }),
          )
      }
      break
    default:
      break
  }
  return next(action)
}
