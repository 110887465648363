import React from 'react'
import styled from 'styled-components'
import Locked from '@bufferapp/ui/Icon/Icons/Locked'
import UpgradeToPro from '../UpgradeToPro'
import Button from '@bufferapp/ui/Button'

const Lock = styled.section`
  position: relative;

  &:before {
    content: '';
    background-color: rgba(255, 255, 255, 0.9);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
`

const LockContent = styled.main`
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 94px;
  color: rgba(44, 75, 255, 0.5);
`

export default class LockedContent extends React.Component {
  state = {
    open: false,
  }

  showModal() {
    this.setState({
      open: true,
    })
  }

  hideModal() {
    this.setState({
      open: false,
    })
  }

  render() {
    const { children } = this.props
    // @ts-expect-error TS(2339) FIXME: Property 'title' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const title = this.props?.title || ''

    // creates a new string from the title by removing whitespace and uses it in the cta view
    // e.g. "Unlock Stories Analytics" will become "UnlockStoriesAnalytics" and the CTA will be
    // analyze-UnlockStoriesAnalytics-lockedContent-upgrade-1
    const cta = `${title.split(' ').join('')}-lockedContent-upgrade-1`
    return (
      <React.Fragment>
        {this.state.open && (
          <UpgradeToPro
            hide={this.hideModal.bind(this)}
            title={title}
            // @ts-expect-error TS(2339) FIXME: Property 'description' does not exist on type 'Rea... Remove this comment to see the full error message
            description={this.props.description}
            cta={cta}
          />
        )}
        <Lock>
          <LockContent>
            <Locked size="extraLarge" />
            {/* @ts-expect-error TS(2740) FIXME: Type '{ label: string; onClick:... Remove this comment to see the full error message */}
            <Button
              label="Upgrade to Unlock"
              onClick={this.showModal.bind(this)}
            />
          </LockContent>
          {children}
        </Lock>
      </React.Fragment>
    )
  }
}
