import PropTypes from 'prop-types'
import React from 'react'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import ReactHighcharts from 'react-highcharts'
import { blueLight, teal } from '@bufferapp/ui/style/colors'
import chartConfig from './chartConfig'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import dayTimezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(dayTimezone)

const chartFillColorBlue = blueLight
const paidFill = teal

// @ts-expect-error TS(7006) FIXME: Parameter 'dailyMetric' implicitly has an 'any' ty... Remove this comment to see the full error message
function prepareSeries(dailyMetric) {
  const organicSeries = Array.from(dailyMetric, (day) => {
    const dayStartTimestamp = dayjs
      // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
      .utc(Number(day.day))
      .startOf('day')
      .valueOf()

    return {
      x: dayStartTimestamp,
      // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
      y: day.metric ? day.metric.value : 0,
      // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
      label: day.metric ? day.metric.label : '',
      // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
      metricKey: day.metric ? day.metric.metricKey : '',
      // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
      currency: day.metric ? day.metric.currency : '',
      // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
      valueFirst: day.metric ? day.metric.valueFirst : false,
      color: chartFillColorBlue,
      states: {
        hover: {
          color: chartFillColorBlue,
          brightness: 0.0,
        },
      },
    }
  })

  const paidSeries = Array.from(dailyMetric, (day) => {
    const dayStartTimestamp = dayjs
      // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
      .utc(Number(day.day))
      .startOf('day')
      .valueOf()

    return {
      x: dayStartTimestamp,
      // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
      y: day.metric ? day.metric.paid : 0,
      // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
      label: day.metric ? day.metric.label : '',
      // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
      metricKey: day.metric ? day.metric.metricKey : '',
      color: paidFill,
      states: {
        hover: {
          color: paidFill,
          brightness: 0.0,
        },
      },
    }
  })

  return [{ data: organicSeries }, { data: paidSeries }]
}

// @ts-expect-error TS(7006) FIXME: Parameter 'dailyMetric' implicitly has an 'any' ty... Remove this comment to see the full error message
function preparePlotOptions(dailyMetric) {
  const areasplineOptions = {
    marker: {
      enabled: false,
      lineWidth: 3,
      radius: 4,
      symbol: 'circle',
      lineColor: chartFillColorBlue,
      fillColor: ReactHighcharts.Highcharts.Color(chartFillColorBlue)
        .brighten(0.1)
        .get('rgba'),
    },
    states: {
      hover: {
        radiusPlus: 1.5,
      },
    },
    lineColor: chartFillColorBlue,
    fillColor: chartFillColorBlue,
  }

  let options = {}
  if (dailyMetric.length <= 15) {
    options = {
      column: {
        pointPlacement: 'between',
        pointWidth: null,
        pointPadding: 0.2,
        borderWidth: 1,
        groupPadding: null,
        stacking: 'normal',
      },
      areaspline: areasplineOptions,
      series: {
        cursor: 'pointer',
      },
    }
  } else if (dailyMetric.length > 15 && dailyMetric.length <= 80) {
    options = {
      column: {
        pointPlacement: 'between',
        pointWidth: null,
        pointPadding: null,
        borderWidth: 1,
        groupPadding: null,
        stacking: 'normal',
      },
      areaspline: areasplineOptions,
      series: {
        cursor: 'pointer',
      },
    }
  } else if (dailyMetric.length > 80) {
    options = {
      column: {
        pointPlacement: 'on',
        pointWidth: null,
        pointPadding: 0,
        borderWidth: 0,
        groupPadding: 0,
        stacking: 'normal',
      },
      areaspline: areasplineOptions,
      series: {
        cursor: 'pointer',
      },
    }
  }
  return options
}

// @ts-expect-error TS(7006) FIXME: Parameter 'a' implicitly has an 'any' type.
function sortValues(a, b) {
  return a - b
}

// @ts-expect-error TS(7006) FIXME: Parameter 'dailyMetric' implicitly has an 'any' ty... Remove this comment to see the full error message
function addMaxMinToYAxis(dailyMetric) {
  // @ts-expect-error TS(7034) FIXME: Variable 'valuesOrganic' implicitly has type 'any[... Remove this comment to see the full error message
  const valuesOrganic = []
  // @ts-expect-error TS(7034) FIXME: Variable 'valuesPaid' implicitly has type 'any[]' ... Remove this comment to see the full error message
  const valuesPaid = []
  // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
  dailyMetric.map((item) => {
    valuesOrganic.push(item.metric.value)
    if (item.metric.paid > 0) {
      valuesPaid.push(item.metric.paid)
    }
  })
  // @ts-expect-error TS(7005) FIXME: Variable 'valuesOrganic' implicitly has an 'any[]'... Remove this comment to see the full error message
  const sortedOrganic = valuesOrganic.sort(sortValues)
  // @ts-expect-error TS(7005) FIXME: Variable 'valuesPaid' implicitly has an 'any[]' ty... Remove this comment to see the full error message
  const sortedPaid = valuesPaid.length > 0 ? valuesPaid.sort(sortValues) : [0]

  const yAxisWithMaxMin = {
    title: { text: null },
    gridLineWidth: 0,
    lineWidth: 0,
    tickWidth: 0,
    labels: {
      enabled: false,
    },
    max:
      // @ts-expect-error TS(7005) FIXME: Variable 'valuesPaid' implicitly has an 'any[]' ty... Remove this comment to see the full error message
      !valuesPaid || !valuesPaid.length
        ? sortedOrganic[sortedOrganic.length - 1]
        : sortedOrganic[sortedOrganic.length - 1] +
          sortedPaid[sortedPaid.length - 1],
    min:
      // @ts-expect-error TS(7005) FIXME: Variable 'valuesPaid' implicitly has an 'any[]' ty... Remove this comment to see the full error message
      !valuesPaid || !valuesPaid.length
        ? sortedOrganic[0]
        : [sortedOrganic[0], sortedPaid[0]].sort(sortValues)[0],
  }

  return yAxisWithMaxMin
}

// @ts-expect-error TS(7006) FIXME: Parameter 'dailyMetric' implicitly has an 'any' ty... Remove this comment to see the full error message
function prepareChartOptions(dailyMetric, chartType, chartSize, controlMinMax) {
  const config = Object.assign({}, chartConfig(chartType, chartSize))
  // @ts-expect-error TS(2741) FIXME: Property 'column' is missing in type '{}' but requ... Remove this comment to see the full error message
  config.plotOptions = preparePlotOptions(dailyMetric)
  const sortedDailyMetric = dailyMetric.sort(
    // @ts-expect-error TS(7006) FIXME: Parameter 'a' implicitly has an 'any' type.
    (a, b) => parseInt(a.day) - parseInt(b.day),
  )
  // @ts-expect-error TS(2339) FIXME: Property 'series' does not exist on type '{ title:... Remove this comment to see the full error message
  config.series = prepareSeries(sortedDailyMetric)

  if (controlMinMax) {
    config.yAxis = addMaxMinToYAxis(dailyMetric)
  }
  return config
}

// @ts-expect-error TS(7031) FIXME: Binding element 'dailyData' implicitly has an 'any... Remove this comment to see the full error message
const GridItemChart = ({ dailyData, chartType, chartSize, controlMinMax }) => {
  const charOptions = prepareChartOptions(
    dailyData,
    chartType,
    chartSize,
    controlMinMax,
  )
  return <ReactHighcharts config={charOptions} />
}

GridItemChart.propTypes = {
  dailyData: PropTypes.arrayOf(
    PropTypes.shape({
      day: PropTypes.string,
      metric: PropTypes.shape({
        diff: PropTypes.number,
        label: PropTypes.string,
        value: PropTypes.number,
      }),
    }),
  ).isRequired,
  chartType: PropTypes.oneOf(['column', 'areaspline']).isRequired,
  chartSize: PropTypes.oneOf(['small', 'default']),
  controlMinMax: PropTypes.bool,
}

GridItemChart.defaultProps = {
  chartSize: 'default',
}

export default GridItemChart
