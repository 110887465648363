import React from 'react'
import styled from 'styled-components'
import reactDOM from 'react-dom/server'
import Text from '@bufferapp/ui/Text'
import Answers from '../../Answers/index'

const Tooltip = styled.section`
  padding: 6px;
`
const Spacer = styled.span`
  display: block;
  height: 0.625rem;
`

// @ts-expect-error TS(7031) FIXME: Binding element 'value' implicitly has an 'any' ty... Remove this comment to see the full error message
const ChartTooltip = ({ value, children }) => (
  <Tooltip>
    <Text type="label" color="white">
      {children}
    </Text>
    <Spacer />
    <Text type="label" color="white">
      {Math.round(value * 100) / 100}% engagement rate
    </Text>
  </Tooltip>
)

export default function tooltipFormatter() {
  // @ts-expect-error TS(2683) FIXME: 'this' implicitly has type 'any' because it does n... Remove this comment to see the full error message
  const point = this.points[0]
  const answer = Answers.forType(point.series.userOptions.metricType)

  return reactDOM.renderToStaticMarkup(
    <ChartTooltip value={point.y}>{answer.getTitle(point.key)}</ChartTooltip>,
  )
}
