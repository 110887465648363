import React, { useState } from 'react'
import { Text } from '@bufferapp/ui'
import CrossIcon from '@bufferapp/ui/Icon/Icons/Cross'
import WarningIcon from '@bufferapp/ui/Icon/Icons/Warning'
import { useSplitEnabled } from '@bufferapp/features'
import { getInactiveChannels } from './Channels'
import { ButtonStyled, Banner } from './style'
import { setDismissedCookie, hasDismissedCookie } from '../utils'
import type { Campaign, Channel } from '~/utils/types'

interface DisconnectedChannelBannerProps {
  campaign: Campaign
  profiles: Channel[]
}

const DisconnectedChannelBanner = ({
  campaign,
  profiles,
}: DisconnectedChannelBannerProps): JSX.Element | null => {
  const [dismissed, setDismissed] = useState(false)
  const { isEnabled: isCampaignsToTagsEnabled } =
    useSplitEnabled('campaigns-to-tags')
  const inactiveChannelsCount = getInactiveChannels(
    campaign.channels,
    profiles,
  ).length
  const singular = inactiveChannelsCount === 1
  const cookieName = `CampaignDisconnectedChannelBanner_${campaign.id}`

  if (inactiveChannelsCount === 0) return null
  if (dismissed) return null
  if (hasDismissedCookie(cookieName)) return null

  return (
    <Banner>
      <WarningIcon size="medium" />
      <Text type="p" color="white">
        Uh-oh! Looks like there{' '}
        {singular ? 'is an account' : 'are multiple channels'} in this{' '}
        {isCampaignsToTagsEnabled ? 'tag' : 'campaign'} that{' '}
        {singular ? 'is' : 'are'} not yet connected in Analyze.{' '}
        <a href="https://account.buffer.com">Connect accounts</a>
      </Text>
      <ButtonStyled
        type="text"
        icon={<CrossIcon />}
        hasIconOnly
        onClick={(): void => {
          setDismissed(true)
          setDismissedCookie(cookieName)
        }}
        label="dismiss"
      />
    </Banner>
  )
}

export default DisconnectedChannelBanner
