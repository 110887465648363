import { actionTypes as profileActionTypes } from '~/profile-selector'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import { actions } from '@bufferapp/async-data-fetch'
import { actionTypes } from './reducer'

// @ts-expect-error TS(7006) FIXME: Parameter 'store' implicitly has an 'any' type.
export default (store) => (next) => (action) => {
  // eslint-disable-line no-unused-vars
  const { dispatch, getState } = store
  switch (action.type) {
    case actionTypes.FETCH:
      dispatch(
        actions.fetch({
          name: 'has_ads_account',
          args: {
            service_id: getState().profiles.selectedProfile.serviceId,
            service: getState().profiles.selectedProfile.service,
          },
        }),
      )
      break
    case profileActionTypes.SELECT_PROFILE:
      if (
        action.profile.service == 'facebook' ||
        action.profile.service == 'instagram'
      )
        dispatch(
          actions.fetch({
            name: 'has_ads_account',
            args: {
              service_id: action.profile.serviceId,
              service: action.profile.service,
            },
          }),
        )
      break
    default:
      break
  }
  return next(action)
}
