// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import { actionTypes as asyncDataFetchActionTypes } from '@bufferapp/async-data-fetch'
import { push } from 'connected-react-router'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { LOCATION_CHANGE } from 'react-router-redux'
import { actions, actionTypes } from './reducer'

// @ts-expect-error TS(7006) FIXME: Parameter 'store' implicitly has an 'any' type.
export default (store) => (next) => (action) => {
  // eslint-disable-line no-unused-vars
  const { dispatch, getState } = store
  // @ts-expect-error TS(7006) FIXME: Parameter 'campaignId' implicitly has an 'any' typ... Remove this comment to see the full error message
  const redirectToCampaign = (campaignId, tagsEnabled) => {
    const urlPrefix = tagsEnabled ? 'tags' : 'campaigns'
    dispatch(push(`/${urlPrefix}/${campaignId}`))
  }
  // @ts-expect-error TS(7006) FIXME: Parameter 'location' implicitly has an 'any' type.
  const isCampaignsPage = (location) => location.match(/campaigns\/?$/)
  // @ts-expect-error TS(7006) FIXME: Parameter 'location' implicitly has an 'any' type.
  const isTagsPage = (location) => location.match(/tags\/?$/)
  // @ts-expect-error TS(7006) FIXME: Parameter 'location' implicitly has an 'any' type.
  const getCampaignIdFromRoute = (location) => {
    const result =
      location.match(/campaigns\/(.*)$/) || location.match(/tags\/(.*)$/)
    return result ? result[1] : null
  }
  next(action)
  switch (action.type) {
    case actionTypes.SELECT:
      const tagsEnabled = getState().campaigns.tagsEnabled
      redirectToCampaign(action.id, tagsEnabled)
      break
    case LOCATION_CHANGE:
      // eslint-disable-next-line no-case-declarations
      const { pathname } = action.payload.location
      if (isCampaignsPage(pathname) || isTagsPage(pathname)) {
        const selectedCampaign = getState().campaignSelector.selectedCampaign
        const tagsEnabled = getState().campaigns.tagsEnabled
        if (selectedCampaign) {
          redirectToCampaign(selectedCampaign.id, tagsEnabled)
        }
      }
      break
    case `campaigns_${asyncDataFetchActionTypes.FETCH_SUCCESS}`: {
      const pathname = getState().router.location.pathname
      const campaignId = getCampaignIdFromRoute(pathname)
      const tagsEnabled = getState().campaigns.tagsEnabled
      if (campaignId) {
        dispatch(actions.select({ id: campaignId }))
      } else if (
        (isCampaignsPage(pathname) || isTagsPage(pathname)) &&
        action.result.length
      ) {
        redirectToCampaign(action.result[0].id, tagsEnabled)
      }
      break
    }
    default:
      break
  }
}
