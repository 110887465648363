// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import { actions } from '@bufferapp/async-data-fetch'
import { actionTypes } from './reducer'
import {
  actionTypes as exportCSVActionTypes,
  actions as exportCSVActions,
} from '~/csv-export'
import {
  actions as exportActions,
  actionTypes as exportActionTypes,
} from '~/png-export'

// @ts-expect-error TS(7031) FIXME: Binding element 'metrics' implicitly has an 'any' ... Remove this comment to see the full error message
const formatDataForCSVExport = ({ metrics }) => {
  if (!metrics.length) {
    return null
  }

  const data = {
    date: [],
    [metrics[0].label]: [],
    [metrics[1].label]: [],
    [metrics[2].label]: [],
    [metrics[3].label]: [],
  }

  Object.keys(metrics[0].daily_totals).forEach((item) => {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    data.date.push(item)
  })

  // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
  metrics.forEach((item, index) => {
    Object.keys(item.daily_totals).forEach((day) => {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
      data[item.label].push(item.daily_totals[day])
    })
  })

  return data
}

// @ts-expect-error TS(7006) FIXME: Parameter 'store' implicitly has an 'any' type.
export default (store) => (next) => (action) => {
  // eslint-disable-line no-unused-vars
  const { dispatch, getState } = store
  const state = getState()
  switch (action.type) {
    case actionTypes.FETCH:
      dispatch(
        actions.fetch({
          name: 'campaign_pulse',
          args: {
            campaignId: action.campaign,
          },
        }),
      )
      break
    case exportCSVActionTypes.EXPORT_TO_CSV_START:
      dispatch(
        exportCSVActions.exportChart(
          'campaign-pulse',
          formatDataForCSVExport(getState().campaignPulse),
        ),
      )
      break
    case exportActionTypes.EXPORT_TO_PNG_START:
      dispatch(
        exportActions.exportChart(
          'js-dom-to-png-campaign-pulse',
          'campaign-pulse',
        ),
      )
      break
    default:
      break
  }
  return next(action)
}
