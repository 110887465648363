// style-loader: Adds some css to the DOM by adding a <style> tag

// load the styles
var content = require("!!../../../../../../common/temp/node_modules/.pnpm/css-loader@1.0.1_webpack@5.76.1/node_modules/css-loader/index.js??ruleSet[1].rules[3].use[1]!../../../../../../common/temp/node_modules/.pnpm/less-loader@4.0.5_less@2.7.2+webpack@5.76.1/node_modules/less-loader/dist/cjs.js!./style.less");
if(typeof content === 'string') content = [[module.id, content, '']];
// add the styles to the DOM
var update = require("!../../../../../../common/temp/node_modules/.pnpm/style-loader@0.15.0/node_modules/style-loader/addStyles.js")(content, {});
if(content.locals) module.exports = content.locals;
// Hot Module Replacement
if(module.hot) {
	// When the styles change, update the <style> tags
	if(!content.locals) {
		module.hot.accept("!!../../../../../../common/temp/node_modules/.pnpm/css-loader@1.0.1_webpack@5.76.1/node_modules/css-loader/index.js??ruleSet[1].rules[3].use[1]!../../../../../../common/temp/node_modules/.pnpm/less-loader@4.0.5_less@2.7.2+webpack@5.76.1/node_modules/less-loader/dist/cjs.js!./style.less", function() {
			var newContent = require("!!../../../../../../common/temp/node_modules/.pnpm/css-loader@1.0.1_webpack@5.76.1/node_modules/css-loader/index.js??ruleSet[1].rules[3].use[1]!../../../../../../common/temp/node_modules/.pnpm/less-loader@4.0.5_less@2.7.2+webpack@5.76.1/node_modules/less-loader/dist/cjs.js!./style.less");
			if(typeof newContent === 'string') newContent = [[module.id, newContent, '']];
			update(newContent);
		});
	}
	// When the module is disposed, remove the <style> tags
	module.hot.dispose(function() { update(); });
}