// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import { actionTypes as asyncDataFetchActionTypes } from '@bufferapp/async-data-fetch'

// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import keyWrapper from '@bufferapp/keywrapper'
import config from './metricsConfig'

export const actionTypes = keyWrapper('CAMPAIGN_POSTS_TABLE', {
  FETCH: 'FETCH',
  SEARCH: 'SEARCH',
  SELECT_COUNT: 'SELECT_COUNT',
  SELECT_METRIC: 'SELECT_METRIC',
  SELECT_ORDER: 'SELECT_ORDER',
  TOGGLE_DROPDOWN: 'TOGGLE_DROPDOWN',
})

const initialState = {
  hasError: false,
  isDescendingSelected: true,
  limit: 5,
  loading: true,
  posts: [],
  searchTerms: [],
  selectedMetric: config.sortMetrics[0],
}

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SEARCH:
      return {
        ...state,
        searching: true,
        searchTerms: action.tags,
      }
    case actionTypes.SELECT_METRIC:
      return {
        ...state,
        isDropdownOpen: false,
        selectedMetric: action.metric,
      }
    case actionTypes.SELECT_COUNT:
      return {
        ...state,
        limit: parseInt(action.postsCount, 10),
      }
    case actionTypes.TOGGLE_DROPDOWN:
      return {
        ...state,
        // @ts-expect-error TS(2339) FIXME: Property 'isDropdownOpen' does not exist on type '... Remove this comment to see the full error message
        isDropdownOpen: !state.isDropdownOpen,
      }
    case actionTypes.SELECT_ORDER:
      return {
        ...state,
        isDescendingSelected: action.isDescendingSelected,
      }

    case `campaign_posts_table_${asyncDataFetchActionTypes.FETCH_START}`:
      return {
        ...state,
        loading: true,
        posts: [],
      }
    case `campaign_posts_table_${asyncDataFetchActionTypes.FETCH_SUCCESS}`:
      return {
        ...state,
        loading: false,
        searching: false,
        posts: action.result,
      }
    case `campaign_posts_table_${asyncDataFetchActionTypes.FETCH_FAIL}`:
      return {
        ...initialState,
        loading: false,
        hasError: true,
      }
    default:
      return state
  }
}

export const actions = {
  // @ts-expect-error TS(7006) FIXME: Parameter 'metric' implicitly has an 'any' type.
  selectMetric(metric) {
    return {
      type: actionTypes.SELECT_METRIC,
      metric,
    }
  },
  toggleDropdown() {
    return {
      type: actionTypes.TOGGLE_DROPDOWN,
    }
  },
  // @ts-expect-error TS(7006) FIXME: Parameter 'postsCount' implicitly has an 'any' typ... Remove this comment to see the full error message
  handlePostsCountClick(postsCount) {
    return {
      type: actionTypes.SELECT_COUNT,
      postsCount,
    }
  },
  // @ts-expect-error TS(7006) FIXME: Parameter 'isDescendingSelected' implicitly has an... Remove this comment to see the full error message
  handlePostsSortClick(isDescendingSelected) {
    return {
      type: actionTypes.SELECT_ORDER,
      isDescendingSelected,
    }
  },
  // @ts-expect-error TS(7006) FIXME: Parameter 'tags' implicitly has an 'any' type.
  search(tags) {
    return {
      type: actionTypes.SEARCH,
      tags,
    }
  },
  fetch: () => ({
    type: actionTypes.FETCH,
  }),
}
