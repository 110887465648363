import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import SummaryTable from '~/summary-table'
import AverageTable from '~/average-table'
import CompareChart from '~/compare-chart'
import BoostedPostsNotice from '~/boosted-posts-notice'
import Resources from '~/resources'

// @ts-expect-error TS(7031) FIXME: Binding element 'match' implicitly has an 'any' ty... Remove this comment to see the full error message
const OverviewTab = ({ match }) => (
  <Fragment>
    <BoostedPostsNotice />
    {(match.params.channel === 'instagram' ||
      match.params.channel === 'facebook') && (
      <Resources referral="overview" channel={match.params.channel} />
    )}
    <SummaryTable profileService={match.params.channel} />
    <AverageTable />
    <CompareChart />
  </Fragment>
)

OverviewTab.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      service: PropTypes.string,
    }),
  }).isRequired,
}

export default connect()(OverviewTab)
