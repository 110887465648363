import moment from 'moment-timezone'

// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import { actionTypes as asyncDataFetchActionTypes } from '@bufferapp/async-data-fetch'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import keyWrapper from '@bufferapp/keywrapper'

export const actionTypes = keyWrapper('DATE_PICKER', {
  OPEN_DATE_PICKER: 'OPEN_DATE_PICKER',
  CLOSE_DATE_PICKER: 'CLOSE_DATE_PICKER',
  SET_MONTH: 'SET_MONTH',
  SET_DATE_RANGE: 'SET_DATE_RANGE',
  SET_START_DATE: 'SET_START_DATE',
  CLEAR_START_DATE: 'CLEAR_START_DATE',
  SET_END_DATE: 'SET_END_DATE',
  CLEAR_END_DATE: 'CLEAR_END_DATE',
  OPEN_CALENDAR: 'OPEN_CALENDAR',
  CLOSE_CALENDAR: 'CLOSE_CALENDAR',
})

// @ts-expect-error TS(7006) FIXME: Parameter 'timestamp' implicitly has an 'any' type... Remove this comment to see the full error message
function getDayFromTimestamp(timestamp) {
  return moment.unix(timestamp).startOf('day')
}

// @ts-expect-error TS(7006) FIXME: Parameter 'momentDay' implicitly has an 'any' type... Remove this comment to see the full error message
function formatDay(momentDay) {
  return momentDay.format('MM/DD/YYYY')
}

// @ts-expect-error TS(7006) FIXME: Parameter 'timestamp' implicitly has an 'any' type... Remove this comment to see the full error message
function formatTimestamp(timestamp) {
  const isValidFormat = moment(timestamp, 'MM/DD/YYYY').isValid()

  if (isValidFormat) {
    return timestamp
  }

  return formatDay(getDayFromTimestamp(timestamp))
}

// @ts-expect-error TS(7006) FIXME: Parameter 'range' implicitly has an 'any' type.
export function calculateDateRange(range) {
  // We need to enfoce the start of the day to make sure
  // that the range is not effected by the time of the day
  let startDate
  let endDate

  moment.tz.setDefault('UTC')

  if (typeof range === 'number') {
    startDate = formatDay(moment().startOf('day').subtract(range, 'days'))
    endDate = formatDay(moment().startOf('day').subtract(1, 'days'))
  } else {
    switch (range) {
      case 'This month':
        startDate = formatDay(moment().startOf('month'))
        // check to see if it's the first day of the month and if it is, send the same start date
        if (moment().date() === 1) {
          endDate = startDate
        } else endDate = formatDay(moment().startOf('day').subtract(1, 'day')) // we gotta check here too
        break
      case 'Last month':
        startDate = formatDay(
          moment().startOf('day').subtract(1, 'months').startOf('month'),
        )
        endDate = formatDay(
          moment().startOf('day').subtract(1, 'months').endOf('month'),
        )
        break
      case 'This week':
        startDate = formatDay(moment().startOf('day').startOf('isoWeek'))
        // check to see if it's Monday and if it is, send the same start date
        if (moment().isoWeekday() === 1) {
          endDate = startDate
        } else endDate = formatDay(moment().startOf('day').subtract(1, 'day'))
        break
      case 'Last week':
        startDate = formatDay(
          moment().startOf('day').subtract(1, 'week').startOf('isoWeek'),
        )
        endDate = formatDay(
          moment().startOf('day').subtract(1, 'week').endOf('isoWeek'),
        )
        break
    }
  }

  moment.tz.setDefault()
  return { startDate, endDate }
}

// @ts-expect-error TS(7006) FIXME: Parameter 'presets' implicitly has an 'any' type.
export function updatePresets(presets, selectedPreset) {
  return {
    // @ts-expect-error TS(7006) FIXME: Parameter 'preset' implicitly has an 'any' type.
    presets: presets.map((preset) => {
      preset.selected = selectedPreset
        ? selectedPreset.range == preset.range
        : preset.range === Infinity
      return preset
    }),
  }
}

export const presets = [
  {
    name: 'This month',
    label: 'This month',
    range: 'This month',
    selected: true,
    disabled: false,
  },
  {
    name: 'Last month',
    label: 'Last month',
    range: 'Last month',
    selected: false,
    disabled: false,
  },
  {
    name: 'This week',
    label: 'This week',
    range: 'This week',
    selected: false,
    disabled: false,
  },
  {
    name: 'Last week',
    label: 'Last week',
    range: 'Last week',
    selected: false,
    disabled: false,
  },
  {
    name: 'Custom',
    label: 'Custom',
    range: Infinity,
    selected: false,
    disabled: false,
  },
]

const initialState = {
  loading: false,
  open: false,
  calendarOpen: false,
  minDate: null,
  maxDate: moment.utc().valueOf(),
  month: moment.utc().startOf('month').unix(),
  ...calculateDateRange('This month'),
  presets,
}

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_MONTH:
      return {
        ...state,
        month: action.date,
      }
    case actionTypes.CLEAR_START_DATE:
      return {
        ...state,
        startDate: null,
      }
    case actionTypes.SET_START_DATE:
      return {
        ...state,
        startDate: action.date,
      }
    case actionTypes.CLEAR_END_DATE:
      return {
        ...state,
        endDate: null,
      }
    case actionTypes.SET_END_DATE:
      return {
        ...state,
        endDate: action.date,
      }
    case actionTypes.SET_DATE_RANGE:
      return {
        ...state,
        ...updatePresets(state.presets, action.preset),
        startDate: action.startDate,
        endDate: action.endDate,
        previousStartDate: null,
        previousEndDate: null,
      }
    case `get_report_${asyncDataFetchActionTypes.FETCH_START}`:
      return {
        ...state,
        loading: true,
      }
    case `get_report_${asyncDataFetchActionTypes.FETCH_FAIL}`:
      return {
        ...initialState,
        loading: false,
        hasError: true,
      }
    case `get_report_${asyncDataFetchActionTypes.FETCH_SUCCESS}`:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.OPEN_DATE_PICKER:
      return {
        ...state,
        open: true,
      }
    case actionTypes.CLOSE_DATE_PICKER:
      return {
        ...state,
        open: false,
        calendarOpen: false,
        // @ts-expect-error TS(2339) FIXME: Property 'previousStartDate' does not exist on typ... Remove this comment to see the full error message
        startDate: state.previousStartDate
          ? // @ts-expect-error TS(2339) FIXME: Property 'previousStartDate' does not exist on typ... Remove this comment to see the full error message
            state.previousStartDate
          : state.startDate,
        // @ts-expect-error TS(2339) FIXME: Property 'previousEndDate' does not exist on type ... Remove this comment to see the full error message
        endDate: state.previousEndDate ? state.previousEndDate : state.endDate,
        // @ts-expect-error TS(2339) FIXME: Property 'previousStartDate' does not exist on typ... Remove this comment to see the full error message
        ...(state.previousStartDate
          ? // @ts-expect-error TS(2339) FIXME: Property 'previousPreset' does not exist on type '... Remove this comment to see the full error message
            updatePresets(state.presets, state.previousPreset)
          : state.presets),
      }
    case actionTypes.OPEN_CALENDAR:
      return {
        ...state,
        calendarOpen: true,
        previousStartDate: state.startDate,
        previousEndDate: state.endDate,
        startDate: null,
        endDate: null,
        previousPreset: state.presets.find((preset) => preset.selected),
        ...updatePresets(state.presets, presets[4]),
      }
    default:
      return state
  }
}

export const actions = {
  open: () => ({
    type: actionTypes.OPEN_DATE_PICKER,
  }),
  close: () => ({
    type: actionTypes.CLOSE_DATE_PICKER,
  }),
  openCalendar: () => ({
    type: actionTypes.OPEN_CALENDAR,
  }),
  // @ts-expect-error TS(7006) FIXME: Parameter 'date' implicitly has an 'any' type.
  setStartDate: (date) => ({
    type: actionTypes.SET_START_DATE,
    date: formatTimestamp(date),
  }),
  clearStartDate: () => ({
    type: actionTypes.CLEAR_START_DATE,
  }),
  // @ts-expect-error TS(7006) FIXME: Parameter 'date' implicitly has an 'any' type.
  setEndDate: (date) => ({
    type: actionTypes.SET_END_DATE,
    date: formatTimestamp(date),
  }),
  clearEndDate: () => ({
    type: actionTypes.CLEAR_END_DATE,
  }),
  // @ts-expect-error TS(7006) FIXME: Parameter 'startDate' implicitly has an 'any' type... Remove this comment to see the full error message
  setDateRange: (startDate, endDate) => ({
    type: actionTypes.SET_DATE_RANGE,
    startDate: formatTimestamp(startDate),
    endDate: formatTimestamp(endDate),
  }),
  // @ts-expect-error TS(7006) FIXME: Parameter 'preset' implicitly has an 'any' type.
  setDatePreset: (preset) => ({
    type: actionTypes.SET_DATE_RANGE,
    preset,
    ...calculateDateRange(preset.range),
  }),
  // @ts-expect-error TS(7006) FIXME: Parameter 'date' implicitly has an 'any' type.
  setMonth: (date) => ({
    type: actionTypes.SET_MONTH,
    date,
  }),
  // @ts-expect-error TS(7006) FIXME: Parameter 'tabId' implicitly has an 'any' type.
  setCurrentTab: (tabId) => ({
    type: actionTypes.SET_CURRENT_TAB,
    tabId,
  }),
  // @ts-expect-error TS(7031) FIXME: Binding element 'preset' implicitly has an 'any' t... Remove this comment to see the full error message
  setDateRangeAndPreset: ({ preset, startDate, endDate }) => ({
    type: actionTypes.SET_DATE_RANGE,
    startDate,
    endDate,
    preset,
  }),
}
