import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { geyser } from '@bufferapp/components'
import { GridItem } from '~/shared-components'

const Container = styled.ul`
  width: 100%;
  padding: 1rem 1rem 0 0;
  box-sizing: border-box;
  border-top: 1px dotted ${geyser};
  display: flex;
  flex-direction: row;
`

// @ts-expect-error TS(7031) FIXME: Binding element 'metrics' implicitly has an 'any' ... Remove this comment to see the full error message
const Metrics = ({ metrics }) => {
  if (metrics.length === 0) {
    return null
  }
  return (
    <Container>
      {/* @ts-expect-error TS(7006) FIXME: Parameter 'metric' implicitly has an 'any' type. */}
      {metrics.map((metric) => (
        <GridItem
          key={metric.key}
          metric={metric}
          showPercentSign={metric.label === 'Eng. Rate'}
        />
      ))}
    </Container>
  )
}

Metrics.propTypes = {
  metrics: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default Metrics
