import UpgradeToPro from './components/UpgradeToPro'

// default export = container
export default UpgradeToPro
// export reducer, actions and action types
export { actions, actionTypes } from './actions'
export { default as middleware } from './middleware'

export { default as LockedContent } from './components/LockedContent'
export { default as Modal } from './components/UpgradeToPro'
