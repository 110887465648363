import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { modal, Overlay } from '@bufferapp/components'

const Card = styled.section`
  width: 660px;
  background: #ffffff;
  border: 1px solid #e2e8ed;
  box-shadow: 0px 0px 10px rgba(48, 71, 89, 0.05);
  box-sizing: border-box;
  border-radius: 5px;
  padding: 2.25rem 1.5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: ${modal};
`

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Modal = (props, children) => {
  if (!props.open) return null
  return (
    <div>
      <Overlay onClick={props.toggle} />
      <Card>{props.children}</Card>
    </div>
  )
}

Modal.defaultProps = {
  open: false,
  toggle: () => {},
}

Modal.propTypes = {
  open: PropTypes.bool,
  toggle: PropTypes.func,
  children: PropTypes.node.isRequired,
}

export default Modal
