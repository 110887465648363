// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import { actions } from '@bufferapp/async-data-fetch'
import { actionTypes as profileActionTypes } from '~/profile-selector'
import { actionTypes as dateActionTypes } from '~/date-picker'
import {
  actionTypes as exportActionTypes,
  actions as exportActions,
} from '~/png-export'
import {
  actionTypes as exportCSVActionTypes,
  actions as exportCSVActions,
} from '~/csv-export'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import dayTimezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(dayTimezone)

// @ts-expect-error TS(7006) FIXME: Parameter 'profiles' implicitly has an 'any' type.
const getSelectedProfileTimezone = (profiles, selectedProfileId) => {
  if (!selectedProfileId) return dayjs.tz.guess()
  // @ts-expect-error TS(7006) FIXME: Parameter 'p' implicitly has an 'any' type.
  const profile = profiles.find((p) => p.id === selectedProfileId)
  return profile.timezone
}

// @ts-expect-error TS(7031) FIXME: Binding element 'metrics' implicitly has an 'any' ... Remove this comment to see the full error message
function getSelectedDailyValue({ metrics }, selectedMetricLabel) {
  // @ts-expect-error TS(7006) FIXME: Parameter 'm' implicitly has an 'any' type.
  return metrics.find((m) => m.label === selectedMetricLabel).value
}

// @ts-expect-error TS(7031) FIXME: Binding element 'metrics' implicitly has an 'any' ... Remove this comment to see the full error message
function getSelectedDailyValuePaid({ metrics }, selectedMetricLabel) {
  // @ts-expect-error TS(7006) FIXME: Parameter 'm' implicitly has an 'any' type.
  const metric = metrics.find((m) => m.label === selectedMetricLabel)
  if ('paid' in metric) {
    return metric.paid.value
  }
  return ''
}

const formatDataForCSVExport = (
  // @ts-expect-error TS(7031) FIXME: Binding element 'selectedMetricLabel' implicitly h... Remove this comment to see the full error message
  { selectedMetricLabel, metrics },
  // @ts-expect-error TS(7031) FIXME: Binding element 'profiles' implicitly has an 'any'... Remove this comment to see the full error message
  { profiles, selectedProfileId },
) => {
  if (!metrics.daily.length) {
    return null
  }
  const paidLabel = `Paid ${selectedMetricLabel}`
  const data = {
    date: [],
    [selectedMetricLabel]: [],
    [paidLabel]: [],
  }
  const timezone = getSelectedProfileTimezone(profiles, selectedProfileId)
  // @ts-expect-error TS(7006) FIXME: Parameter 'dailyData' implicitly has an 'any' type... Remove this comment to see the full error message
  metrics.daily.forEach((dailyData) => {
    const value = getSelectedDailyValue(dailyData, selectedMetricLabel)
    // @ts-expect-error TS(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
    data[selectedMetricLabel].push(value)

    const valuePaid = getSelectedDailyValuePaid(dailyData, selectedMetricLabel)
    // @ts-expect-error TS(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
    data[paidLabel].push(valuePaid)

    const day = dailyData.day
    const formattedDay = dayjs
      .unix(day / 1000)
      .tz(timezone)
      .format('MM/DD/YYYY')
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    data.date.push(formattedDay)
  })
  return data
}

// @ts-expect-error TS(7006) FIXME: Parameter 'store' implicitly has an 'any' type.
export default (store) => (next) => (action) => {
  // eslint-disable-line no-unused-vars
  const { dispatch, getState } = store
  const exportFilename = 'metrics-insights'
  switch (action.type) {
    case profileActionTypes.SELECT_PROFILE:
      if (action.profile.service !== 'linkedin') {
        dispatch(
          actions.fetch({
            name: 'compare',
            args: {
              profileId: action.profile.id,
              profileService: action.profile.service,
              startDate: getState().date.startDate,
              endDate: getState().date.endDate,
            },
          }),
        )
      }
      break
    case profileActionTypes.REFRESH_PROFILES:
      if (getState().profiles.selectedProfileId) {
        if (getState().profiles.selectedProfile.service !== 'linkedin') {
          dispatch(
            actions.fetch({
              name: 'compare',
              args: {
                profileId: getState().profiles.selectedProfileId,
                profileService: getState().profiles.selectedProfileService,
                startDate: getState().date.startDate,
                endDate: getState().date.endDate,
              },
            }),
          )
        }
      }
      break
    case dateActionTypes.SET_DATE_RANGE:
      if (getState().profiles.selectedProfileId) {
        if (getState().profiles.selectedProfile.service !== 'linkedin') {
          dispatch(
            actions.fetch({
              name: 'compare',
              args: {
                profileId: getState().profiles.selectedProfileId,
                profileService: getState().profiles.selectedProfileService,
                startDate: action.startDate,
                endDate: action.endDate,
              },
            }),
          )
        }
      }
      break
    case exportCSVActionTypes.EXPORT_TO_CSV_START:
      dispatch(
        exportCSVActions.exportChart(
          exportFilename,
          formatDataForCSVExport(getState().compare, getState().profiles),
        ),
      )
      break
    case exportActionTypes.EXPORT_TO_PNG_START:
      dispatch(
        exportActions.exportChart('js-dom-to-png-compare', exportFilename),
      )
      break
    default:
      break
  }
  return next(action)
}
