import { actionTypes } from './actions'

// @ts-expect-error TS(7006) FIXME: Parameter 'store' implicitly has an 'any' type.
export default (store) => (next) => (action) => {
  // eslint-disable-line no-unused-vars
  switch (action.type) {
    case actionTypes.UPGRADE_TO_PRO:
    default:
      return next(action)
  }
}
