// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import { actionTypes as asyncDataFetchActionTypes } from '@bufferapp/async-data-fetch'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import keyWrapper from '@bufferapp/keywrapper'

export const actionTypes = keyWrapper('REPORT', {
  REMOVE_REPORT: 'REMOVE_REPORT',
  FETCH: 'FETCH',
})

const initialState = {
  reports: [],
  loading: true,
  hasError: false,
}

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
export default (state = initialState, action) => {
  switch (action.type) {
    case `list_reports_${asyncDataFetchActionTypes.FETCH_START}`:
      return initialState
    case `create_report_${asyncDataFetchActionTypes.FETCH_SUCCESS}`:
      return {
        ...state,
        reports: [
          {
            ...action.result,
          },
          ...state.reports,
        ],
      }
    case `list_reports_${asyncDataFetchActionTypes.FETCH_FAIL}`:
      return {
        ...initialState,
        loading: false,
        hasError: true,
      }
    case `list_reports_${asyncDataFetchActionTypes.FETCH_SUCCESS}`:
      return {
        ...state,
        loading: false,
        reports: action.result,
      }
    case `remove_report_${asyncDataFetchActionTypes.FETCH_SUCCESS}`:
      return {
        ...state,
        reports: state.reports.filter(
          // @ts-expect-error TS(2339) FIXME: Property '_id' does not exist on type 'never'.
          (report) => report._id !== action.result.id,
        ),
      }
    default:
      return state
  }
}

export const actions = {
  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  removeReport: (id) => ({
    type: actionTypes.REMOVE_REPORT,
    id,
  }),
  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  fetch: (id) => ({
    type: actionTypes.FETCH,
    id,
  }),
}
