import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { white, geyser } from '@bufferapp/components'
import ErrorBoundary from '../ErrorBoundary'

const Container = styled.article`
  width: 100%;
  background: ${white};
  border: 1px solid ${geyser};
  border-radius: 4px;
  box-shadow: 0px 0px 10px rgba(48, 71, 89, 0.05);
  margin: 0 0 16px;

  &:after {
    clear: both;
    content: '';
    display: block;
  }
`

// @ts-expect-error TS(7031) FIXME: Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
const ChartCard = ({ children, hasError }) => (
  <Container data-chart>
    {/* @ts-expect-error TS(2786) FIXME: 'ErrorBoundary' cannot be used as a JSX component. */}
    <ErrorBoundary hasError={hasError}>{children}</ErrorBoundary>
  </Container>
)

ChartCard.defaultProps = {
  hasError: false,
}

ChartCard.propTypes = {
  children: PropTypes.node.isRequired,
  hasError: PropTypes.node,
}

export default ChartCard
